import { Stack, Typography } from '@mui/material';
import {
  SchoolYear,
  useGetDependantStudentsForParentQuery,
  useGetEnrollments,
  useGetStudentProductsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { EyeIcon, Loading, PlusIcon } from '@schooly/style';
import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../../../components/common/AccessDenied';
import {
  PayableFees,
  PayableFeesHeaderTitle,
} from '../../../components/common/PayableFees/PayableFees';
import EmptyStub from '../../../components/common/ProfileModal/EmptyStub';
import { ProfileModalMode } from '../../../context/profile/helpers';
import { useProfile } from '../../../context/profile/useProfile';

type ProfilePayableFeesProps = {
  relationId: string;
};

export const ProfilePayableFeesParent: FC<ProfilePayableFeesProps> = ({ relationId }) => {
  const { permissions } = useAuth();
  const canView = permissions.includes('product_and_invoice_viewer');

  const { data: students, isLoading: isLoadingStudents } = useGetDependantStudentsForParentQuery(
    { id: relationId },
    { enabled: canView },
  );

  const { actions } = useProfile();

  if (!canView) {
    return <AccessDenied />;
  }

  if (!students?.length && !isLoadingStudents) {
    return (
      <EmptyStub
        tabNameTextId="profile-PayableFees"
        mode={ProfileModalMode.PayableFees}
        messageTextId="profile-NoStudentAssigned"
        buttonTextId="profile-GoToFamilyPage"
        onButtonClick={() => actions.setMode(ProfileModalMode.Family)}
        buttonIcon={<EyeIcon />}
      />
    );
  }
  return (
    <>
      {!students || isLoadingStudents ? (
        <>
          <PayableFeesHeaderTitle />
          <Stack flex={1}>
            <Loading />
          </Stack>
        </>
      ) : (
        <PayableFees relationType="parent" id={relationId} />
      )}
    </>
  );
};

export const ProfilePayableFeesStudent: FC<ProfilePayableFeesProps> = ({ relationId }) => {
  const { permissions, schoolId = '' } = useAuth();
  const canView = permissions.includes('product_and_invoice_viewer');

  const { data: studentProducts, isLoading: isProductsLoading } = useGetStudentProductsQuery(
    {
      relationId,
    },
    { refetchOnMount: 'always', enabled: canView },
  );

  const { data, isLoading: isEnrollmentsLoading } = useGetEnrollments(
    {
      schoolId,
      studentId: relationId,
    },
    { refetchOnMount: 'always', enabled: canView },
  );

  const products = studentProducts?.products;
  const isLoading = isEnrollmentsLoading || isProductsLoading;

  const schoolYears = useMemo(
    () =>
      data?.enrollments.reduce<SchoolYear[]>((acc, next) => {
        const yearExists = acc.find((y) => y.id === next.school_year.id);

        return yearExists ? acc : [...acc, next.school_year];
      }, []),
    [data?.enrollments],
  );

  if (!canView) {
    return <AccessDenied />;
  }

  return (
    <>
      {!products?.length || !schoolYears || isLoading ? (
        <>
          {isLoading && (
            <>
              <PayableFeesHeaderTitle />
              <Stack flex={1}>
                <Loading />
              </Stack>
            </>
          )}
          {!isLoading && !products?.length && <PayableFeesEmptyStub />}
        </>
      ) : (
        <PayableFees relationType="student" id={relationId} schoolYears={schoolYears} />
      )}
    </>
  );
};

const PayableFeesEmptyStub = () => {
  const { permissions } = useAuth();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const canCreateSingleInvoices = permissions.includes('product_and_invoice_creator');

  return (
    <EmptyStub
      tabNameTextId="profile-PayableFees"
      mode={ProfileModalMode.PayableFees}
      messageTextId="profile-ThereAreNoActiveProductAssignmentsForStudent"
      buttonTextId="profile-AddSingleInvoice"
      onButtonClick={
        canCreateSingleInvoices ? () => navigate({ pathname: 'single-invoices' }) : undefined
      }
      buttonIcon={<PlusIcon />}
      textWidth={280}
    >
      {canCreateSingleInvoices && (
        <Typography pt={2.5} variant="h3" color="common.grey2" width={320} textAlign="center">
          {formatMessage({ id: 'singleInvoices-CreateInvoice' })}
        </Typography>
      )}
    </EmptyStub>
  );
};
