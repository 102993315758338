import { Checkbox, Radio, Stack, StackProps } from '@mui/material';
import { FilterValue, SelectOption } from '@schooly/api';
import { RadioOnIcon, RadioOnIconNegative, RadioOnIconPositive } from '@schooly/style';
import React, { ReactElement, useCallback } from 'react';
import { FieldValues } from 'react-hook-form';
import { Controller, ControllerProps, ControllerRenderProps } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';

import { renderError } from '../../ui/Input/helpers';
import { BaseFormInputProps, FormInput2Context, useFormInput2 } from '../../ui/Input/utils';
import { FormCheckboxStyled } from './FormCheckbox.styled';
import { FormRadioStyled } from './FormRadio.styled';

type PickedInputProps = Pick<React.InputHTMLAttributes<HTMLInputElement>, 'defaultValue'>;

interface FormRadioGroupProps<T>
  extends Omit<ControllerProps<FieldValues>, 'name' | 'render' | 'defaultValue'>,
    BaseFormInputProps,
    PickedInputProps {
  options: SelectOption<T>[];
  withBorder?: boolean;
  withBackground?: boolean;
  containerProps?: StackProps;
  listView?: boolean;
  renderOption?: (
    option: SelectOption<T, any>,
    formContext: FormInput2Context,
    field: ControllerRenderProps<Record<string, any>, string>,
  ) => ReactElement<any, any>;
}

export const FormRadioGroup = <T extends any = FilterValue>({
  options,
  withBorder = true,
  containerProps,
  withBackground,
  listView,
  renderOption,
  ...props
}: FormRadioGroupProps<T>) => {
  const formInputContext = useFormInput2(props);

  const { control, error, fullName, value } = formInputContext;

  const handleClick = useCallback(
    (onChange: (val?: T) => void, optionValue: T) => () => {
      onChange(optionValue);
    },
    [],
  );

  return (
    <Controller
      {...props}
      control={control}
      name={fullName}
      render={({ field }) => (
        <Stack
          direction={listView ? 'column' : 'row'}
          alignItems={listView ? 'flex-start' : 'center'}
          gap={1.5}
          {...containerProps}
        >
          {options.map((option) => {
            const CheckedIcon = option.checkColor
              ? option.checkColor === 'POSITIVE'
                ? RadioOnIconPositive
                : RadioOnIconNegative
              : RadioOnIcon;

            if (listView && !withBorder) {
              return renderOption ? (
                renderOption(option, formInputContext, field)
              ) : (
                <FormRadioStyled
                  key={`${option.value}`}
                  label={
                    option.labelTextId ? <FormattedMessage id={option.labelTextId} /> : option.label
                  }
                  error={error}
                  disabled={props.disabled}
                  control={
                    <Radio
                      checked={`${option.value}` === String(value)}
                      onChange={handleClick(field.onChange, option.value)}
                    />
                  }
                />
              );
            }

            return renderOption ? (
              renderOption(option, formInputContext, field)
            ) : (
              <FormCheckboxStyled
                key={`${option.value}`}
                disabled={props.disabled}
                control={
                  <Checkbox
                    name={field.name}
                    checked={`${option.value}` === String(value)}
                    onChange={handleClick(field.onChange, option.value)}
                    checkedIcon={<CheckedIcon className="reset-svg-currentColor" />}
                    data-cy={`checkbox-${field.name}-${option.label}`}
                  />
                }
                label={
                  option.labelTextId ? <FormattedMessage id={option.labelTextId} /> : option.label
                }
                error={error}
                withBorder={withBorder}
                withBackground={withBackground}
              />
            );
          })}
          {renderError(error)}
        </Stack>
      )}
    />
  );
};
