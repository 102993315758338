import {
  Avatar,
  Backdrop,
  Box,
  Button,
  ClickAwayListener,
  Icon,
  IconButton,
  InputAdornment,
  Stack,
  StackProps,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DATE_FORMAT_RANGE_FNS,
  DEFAULT_DATE_FORMAT_FNS,
  Guardian,
  LegalEntity,
  PaymentFrequency,
  SingleInvoiceAssignedProduct,
  SingleInvoicePayer,
  SingleInvoiceStudent,
  useCreateSingleInvoicesMutation,
  useGetStudentMembership,
  useGetStudentProductsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { DateSelect, ExpandedSelect } from '@schooly/components/filters';
import { FormNumberSelect } from '@schooly/components/form-text-field';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolInviteStatus } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ArrowDownV2Icon,
  CompanyIcon,
  CrossIcon,
  DeleteIcon,
  DoneIcon,
  InvoiceIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain,
  Price,
  SkeletonRowsComponent,
  Spin,
  StarIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { isNotEmpty } from '@schooly/utils/predicates';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { addDays, format } from 'date-fns';
import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import {
  Controller,
  FieldErrors,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import AccessDenied from '../../../../../components/common/AccessDenied';
import { useRouter } from '../../../../../context/router/useRouter';
import { getCurrencySymbol } from '../../../../../pages/School/SchoolProducts/helpers';
import { InviteWarning } from '../../ProfileModalPayers/StudentProductsModal/StudentDefaultPayerSelect';
import { ProductContent } from './Products/ProductContent';
import { getPayerId, getProductsTotal } from './utils';

const DUE_DAYS_COUNT_MIN = 0;
const DUE_DAYS_COUNT_MAX = Number.MAX_SAFE_INTEGER;

type SingleInvoiceGuardian = Guardian & { primary: boolean };

export type SingleInvoiceData = {
  students: SingleInvoiceStudent[];
  guardians?: SingleInvoiceGuardian[];
  currentGuardian: Omit<SingleInvoiceGuardian, 'schools'>;
};

type SingleInvoiceContentProps = {
  singleInvoiceData: SingleInvoiceData;
  oneOffFrequency: PaymentFrequency;
};

type SingleInvoiceFormItem = {
  student: SingleInvoiceStudent;
  payer: SingleInvoicePayer;
  products: SingleInvoiceAssignedProduct[];
};

export type SingleInvoiceForm = {
  assignments: SingleInvoiceFormItem[];
  invoice_date: string;
  due_days_count: number;
  generation_date: string;
};

export const SingleInvoiceContent: FC<SingleInvoiceContentProps> = ({
  singleInvoiceData,
  oneOffFrequency,
}) => {
  const { goBack } = useRouter();
  const { formatMessage } = useIntl();
  const { showError, showNotification } = useNotifications();
  const createSingleInvoices = useCreateSingleInvoicesMutation();
  const { permissions, schoolId = '' } = useAuth();

  const canCreate = permissions.includes('product_and_invoice_creator');

  const { getConfirmation } = useConfirmationDialog();

  const form = useForm<SingleInvoiceForm>({
    defaultValues: {
      assignments: singleInvoiceData.students.map((s) => ({
        student: s,
        payer: { type: 'guardian' as const, ...singleInvoiceData.currentGuardian },
      })),
      generation_date: format(newDateTimezoneOffset(), DEFAULT_DATE_FORMAT_FNS),
      invoice_date: format(newDateTimezoneOffset(), DEFAULT_DATE_FORMAT_FNS),
      due_days_count: oneOffFrequency.due_days_count ?? 0,
    },
  });

  const { fields, remove } = useFieldArray({
    control: form.control,
    name: 'assignments',
  });

  const dueDaysCount = form.watch('due_days_count');
  const isDueDaysCountSelected = Number.isInteger(dueDaysCount);

  const handleError = useCallback(
    (errors: FieldErrors<SingleInvoiceForm>) => {
      if (!errors.assignments) return;

      const hasNoProductError = errors.assignments?.some?.(
        (e) => e?.products?.root?.message === 'singleInvoices-NoProductError',
      );

      if (hasNoProductError) {
        showError({ message: formatMessage({ id: 'singleInvoices-NoProductError' }) });
      }
    },
    [formatMessage, showError],
  );
  const handleSubmit = useCallback(
    async ({ assignments, due_days_count, invoice_date }: SingleInvoiceForm) => {
      const today = newDateTimezoneOffset();

      // To be used when BE is ready
      // const invoicesKeys = assignments.reduce<string[]>((acc, { payer, student, products }) => {
      //   products.forEach(({ billing_connection }) => {
      //     const key = [
      //       getPayerId(payer),
      //       payer.type === 'company' ? student.relation_id : billing_connection.legal_entity_id,
      //     ].join(':');
      //     if (!acc.includes(key)) acc.push(key);
      //   });

      //   return acc;
      // }, []);

      // const invoicesCount = Object.keys(invoicesKeys).length;
      const invoicesCount = assignments.length;

      const productsTotal = getProductsTotal(assignments.flatMap((a) => a.products)).reduce(
        (acc, { currency, total }, i, arr) => {
          const label = `${getCurrencySymbol(currency)} ${total}`;
          if (arr.length <= 1 || !acc) return label;
          if (i === arr.length - 1) return `${acc} ${formatMessage({ id: 'and' })} ${label}`;
          return `${acc}, ${label}`;
        },
        '',
      );

      const invoicesTitle = formatMessage({
        id: invoicesCount > 1 ? 'singleInvoices-Invoices' : 'singleInvoices-Invoice',
      }).toLowerCase();

      const isConfirmed = await getConfirmation({
        textId: 'singleInvoices-Confirmation',
        textValues: {
          prices: productsTotal,
          invoicesCount: invoicesCount > 1 ? `${invoicesCount} ${invoicesTitle} ` : invoicesTitle,
        },
        content: (
          <Box pt={2.75} pb={1}>
            <SingleInvoicesResult assignments={assignments} />
          </Box>
        ),
      });

      if (!isConfirmed) return;

      createSingleInvoices.mutateAsync(
        {
          school_id: schoolId,
          single_invoice: {
            due_date: format(addDays(today, due_days_count), DEFAULT_DATE_FORMAT_FNS),
            generation_date: format(today, DEFAULT_DATE_FORMAT_FNS),
            invoice_date,
            assignments: assignments.map(({ payer, student, products }) => ({
              payer: {
                id: getPayerId(payer),
                type: payer.type,
              },
              relation_id: student.relation_id,
              products: products.map(({ billing_connection, price, ...rest }) => rest),
            })),
          },
        },
        {
          onSuccess: () => {
            goBack();
            showNotification({
              message: formatMessage({
                id:
                  invoicesCount > 1
                    ? 'singleInvoices-InvoicesSuccessfulCreation'
                    : 'singleInvoices-InvoiceSuccessfulCreation',
              }),
            });
          },
          onError: showError,
        },
      );
    },
    [
      createSingleInvoices,
      formatMessage,
      getConfirmation,
      goBack,
      schoolId,
      showError,
      showNotification,
    ],
  );

  const handleCloseWithConfirm = useCallback(async () => {
    if (
      form.formState.isDirty &&
      !(await getConfirmation({ textId: 'school-edit-CloseUnsavedConfirmation' }))
    ) {
      return;
    }

    goBack();
  }, [form.formState.isDirty, getConfirmation, goBack]);

  if (!canCreate) return <AccessDenied />;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit, handleError)}>
        <ModalHeader title={formatMessage({ id: 'singleInvoices-Create' })} active>
          <IconButton onClick={handleCloseWithConfirm}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalMain>
          <ModalContent active sx={{ pb: 0 }}>
            <Stack direction="row" gap={2}>
              <Stack flex={1}>
                <Controller
                  control={form.control}
                  name="invoice_date"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <DateSelect
                      ref={field.ref}
                      onSetDate={(date) => {
                        field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
                      }}
                      date={field.value}
                      placeholder={formatMessage({ id: 'frequencies-InvoiceDate' })}
                      requiredLabel="required"
                      error={fieldState.error}
                      onClear={() => field.onChange('')}
                    />
                  )}
                />
              </Stack>
              <Stack flex={1}>
                <FormNumberSelect
                  name="due_days_count"
                  min={DUE_DAYS_COUNT_MIN}
                  label={formatMessage({ id: 'frequencies-DueOn' })}
                  max={DUE_DAYS_COUNT_MAX}
                  rules={{
                    required: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {formatMessage({ id: 'frequencies-DueDateDescriptionTitle' })}
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    visibility: isDueDaysCountSelected ? 'visible' : 'hidden',
                    pl: 1.5,
                    pt: 0.25,
                    color: 'common.grey',
                  }}
                >
                  {formatMessage({ id: 'frequencies-DueDate' })}:{' '}
                  {isDueDaysCountSelected &&
                    format(addDays(newDateTimezoneOffset(), dueDaysCount), DATE_FORMAT_RANGE_FNS)}
                </Typography>
              </Stack>
            </Stack>

            <Stack gap={2}>
              {fields.map((s, i) => (
                <SingleInvoiceRow
                  key={s.id}
                  data={s}
                  index={i}
                  guardians={singleInvoiceData.guardians}
                  onDelete={fields.length > 1 ? () => remove(i) : undefined}
                />
              ))}
            </Stack>

            <Box pt={1}>
              <SingleInvoiceFormTotal />
            </Box>
          </ModalContent>
        </ModalMain>
        <ModalFooter active>
          <Button
            type="submit"
            disabled={createSingleInvoices.isLoading}
            startIcon={createSingleInvoices.isLoading ? <Spin /> : <InvoiceIcon />}
          >
            <FormattedMessage id="singleInvoices-IssueInvoice" />
          </Button>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};

type SingleInvoiceRowProps = {
  data: SingleInvoiceFormItem;
  index: number;
  guardians?: SingleInvoiceGuardian[];
  onDelete?: () => void;
};

export const SingleInvoiceRow: FC<SingleInvoiceRowProps> = ({
  data,
  index,
  guardians,
  onDelete,
}) => {
  const { schoolId = '' } = useAuth();
  const { setValue, watch } = useFormContext<SingleInvoiceForm>();
  const selectedPayer = watch(`assignments.${index}.payer`);

  return (
    <Box>
      <Stack direction="row" alignItems="center" pt={3} pb={1.75}>
        <Stack direction="row" gap={1} flex={'0 0 52%'}>
          <AvatarAuth user={data.student} />
          <Typography variant="h2">{getUserFullName(data.student)}</Typography>
        </Stack>
        <Stack flex={1}>
          <PayerDropdown
            schoolId={schoolId}
            onSelect={(payer) => {
              setValue(`assignments.${index}.payer`, payer);
            }}
            relationId={data.student.relation_id}
            selectedPayer={selectedPayer}
            guardians={guardians}
          />
        </Stack>
        {onDelete && (
          <IconButton
            inverse
            onClick={onDelete}
            sx={{
              mr: 1.75,
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      {selectedPayer.type === 'guardian' &&
        selectedPayer.invite_status !== SchoolInviteStatus.Active && (
          <ParentInviteNotification schoolId={schoolId} guardian={selectedPayer} />
        )}
      <ProductContent index={index} schoolId={schoolId} />
    </Box>
  );
};

type PayerDropdownProps = {
  selectedPayer: SingleInvoicePayer;
  relationId: string;
  schoolId: string;
  onSelect: (v: SingleInvoicePayer) => void;
  guardians?: SingleInvoiceGuardian[];
};

export const PayerDropdown: FC<PayerDropdownProps> = ({
  relationId,
  selectedPayer,
  schoolId,
  onSelect,
  guardians: initialGuardians,
}) => {
  const [opened, open, close] = useFlag();
  const { formatMessage } = useIntl();

  const { data: productsData, isLoading: isProductsLoading } = useGetStudentProductsQuery(
    {
      relationId,
    },
    { enabled: opened },
  );

  const { data: studentMemberShip, isFetching: isMembershipLoading } = useGetStudentMembership(
    {
      id: relationId,
      schoolId,
    },
    { enabled: opened && !initialGuardians },
  );

  const renderContent = useCallback(() => {
    const isLoading = isProductsLoading || isMembershipLoading;

    if (isLoading) return <SkeletonRowsComponent height={20} rowCount={3} gap={1.5} p={1.5} />;

    const guardians =
      initialGuardians ??
      studentMemberShip?.guardian_relations?.reduce<SingleInvoiceGuardian[]>(
        (acc, { guardian, primary }) => [...acc, { ...guardian, primary: !!primary }],
        [],
      );
    const companyPayer = productsData?.company_payer;

    if (!guardians?.length && !companyPayer) {
      return (
        <Typography px={1} py={1.5}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );
    }

    return (
      <Box px={1} py={0.5}>
        {guardians?.map((g) => {
          const isSelected =
            selectedPayer.type === 'guardian' && selectedPayer.relation_id === g.relation_id;

          const isActive = g.invite_status === SchoolInviteStatus.Active;

          return (
            <SelectRow
              key={g.relation_id}
              onSelect={() => {
                onSelect({ type: 'guardian', ...g });
                close();
              }}
              isSelected={isSelected}
            >
              <PayerContent payer={{ type: 'guardian', ...g }}>
                {g.primary && (
                  <Icon sx={{ color: 'common.grey2' }}>
                    <StarIcon />
                  </Icon>
                )}
              </PayerContent>
              {!isActive && (
                <Stack flex={1} alignItems="flex-end">
                  <Box
                    sx={(theme) => ({
                      width: theme.spacing(),
                      height: theme.spacing(),
                      borderRadius: '50%',
                      backgroundColor: 'warning.main',
                    })}
                  />
                </Stack>
              )}
            </SelectRow>
          );
        })}
        {!!companyPayer && (
          <SelectRow
            onSelect={() => {
              onSelect({ type: 'company', ...companyPayer });
              close();
            }}
            isSelected={selectedPayer.type === 'company' && selectedPayer.id === companyPayer.id}
          >
            <PayerContent payer={{ type: 'company', ...companyPayer }} />
          </SelectRow>
        )}
      </Box>
    );
  }, [
    close,
    initialGuardians,
    isMembershipLoading,
    isProductsLoading,
    onSelect,
    productsData,
    selectedPayer,
    studentMemberShip?.guardian_relations,
  ]);

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={close}>
        <div>
          <Tooltip
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [-12, -41],
                  },
                },
              ],
            }}
            onClose={close}
            open={opened}
            placement="bottom-start"
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  width: 475,
                  maxWidth: 475,
                  borderRadius: theme.spacing(1),
                  border: `1px solid ${theme.palette.common.light3}`,
                  padding: 0,
                  overflow: 'hidden',
                  margin: '0 !important',
                }),
              },
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Box
                sx={{
                  '.header': {
                    minHeight: '43px',
                  },
                }}
              >
                <ExpandedSelect
                  width={475}
                  hasSelectedValue={!!selectedPayer}
                  renderContent={renderContent}
                  onClose={close}
                >
                  <PayerContent
                    labelText={formatMessage({ id: 'profile-Payer' })}
                    pl={0.5}
                    payer={selectedPayer}
                    isSelected
                  />
                </ExpandedSelect>
              </Box>
            }
          >
            <Stack
              onClick={opened ? close : open}
              direction="row"
              sx={(theme) => ({
                cursor: 'pointer',
                zIndex: opened ? theme.zIndex.drawer + 2 : undefined,
                ':hover .arrowDropdown': {
                  path: {
                    stroke: theme.palette.primary.main,
                  },
                },
              })}
              alignItems="center"
              gap={1.75}
            >
              <PayerContent
                labelText={formatMessage({ id: 'profile-Payer' })}
                payer={selectedPayer}
                isSelected
              >
                <IconButton
                  sx={(theme) => ({
                    width: theme.spacing(1),
                    path: {
                      stroke: theme.palette.common.grey,
                    },
                  })}
                >
                  <ArrowDownV2Icon className="arrowDropdown" />
                </IconButton>
              </PayerContent>
            </Stack>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

type SelectRowProps = {
  onSelect: () => void;
  isSelected?: boolean;
};

export const SelectRow: FC<PropsWithChildren<SelectRowProps>> = ({
  onSelect,
  isSelected,
  children,
}) => {
  return (
    <Stack
      className="layout"
      onClick={onSelect}
      flexDirection="row"
      alignItems="center"
      px={0.5}
      gap={2}
      py={0.75}
      sx={(theme) => ({
        cursor: 'pointer',
        justifyContent: 'space-between',

        ...(isSelected && {
          '.MuiTypography-root': {
            color: 'primary.main',
          },
        }),
        '&:hover': {
          backgroundColor: theme.palette.background.default,
          borderRadius: 0.5,
          '.MuiTypography-root': {
            color: 'primary.main',
          },
        },
      })}
    >
      {children}
      <Icon
        sx={{
          visibility: isSelected ? 'visible' : 'hidden',
        }}
      >
        <DoneIcon />
      </Icon>
    </Stack>
  );
};

type PayerContentProps = {
  payer: SingleInvoiceFormItem['payer'];
  isSelected?: boolean;
  labelText?: string;
} & StackProps;

const PayerContent: FC<PropsWithChildren<PayerContentProps>> = ({
  payer,
  isSelected,
  children,
  labelText,
  ...props
}) => {
  return (
    <Stack direction="row" gap={1} alignItems="center" {...props}>
      {payer.type === 'company' ? (
        <Avatar
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary.main,
          })}
        >
          <CompanyIcon />
        </Avatar>
      ) : (
        <AvatarAuth user={payer} />
      )}

      <Stack>
        {labelText && (
          <Typography variant="caption" color="common.grey">
            {labelText}
          </Typography>
        )}

        <Stack direction="row" gap={2}>
          <TypographyWithOverflowHint>
            <Typography
              display="inline"
              flex={1}
              color={!isSelected ? 'common.grey2' : undefined}
              variant="h3"
              noWrap
            >
              {payer.type === 'company' ? payer.name : getUserFullName(payer)}
            </Typography>
          </TypographyWithOverflowHint>

          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};

export const SingleInvoiceFormTotal: FC = () => {
  const { formatMessage } = useIntl();
  const form = useFormContext<SingleInvoiceForm>();
  const assignments = form.watch('assignments');
  const products = assignments.flatMap((p) => p.products).filter(isNotEmpty);

  const productsTotal = useMemo(
    () => (!!products.length ? getProductsTotal(products) : []),
    [products],
  );

  if (!productsTotal.length) return null;

  return (
    <Stack
      alignItems="end"
      sx={(theme) => ({
        borderTop: theme.mixins.borderValue(),
        borderWidth: '3px',
        py: 1.5,
      })}
    >
      <Stack width={216} gap={0.5}>
        {productsTotal.map(({ currency, total }, i) => {
          const orderNumber = i + 1;
          return (
            <Stack key={currency} direction="row" alignItems="center" gap={1.5}>
              <Typography variant="h3">
                {formatMessage({ id: 'people-TotalCount' })}{' '}
                {productsTotal.length > 1 && orderNumber}:
              </Typography>
              <Price currency={getCurrencySymbol(currency)} price={total} color="primary.main" />
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

type SingleInvoicesResultProps = {
  assignments: SingleInvoiceFormItem[];
};

export const SingleInvoicesResult: FC<SingleInvoicesResultProps> = ({ assignments }) => {
  const { formatMessage } = useIntl();

  const { legalEntities, uniquePayers } = useMemo(() => {
    return {
      uniquePayers: assignments.reduce<SingleInvoicePayer[]>(
        (acc, { payer }) =>
          acc.find((p) => getPayerId(p) === getPayerId(payer)) ? acc : [...acc, payer],
        [],
      ),
      legalEntities: assignments
        .flatMap((a) => a.products)
        .reduce<Record<LegalEntity['id'], LegalEntity['display_name']>>(
          (acc, { billing_connection: { legal_entity_id, legal_entity_display_name } }) => {
            return { ...acc, [legal_entity_id]: legal_entity_display_name };
          },
          {},
        ),
    };
  }, [assignments]);

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 3fr)',
        columnGap: theme.spacing(1),
        '.MuiAvatar-root': {
          width: theme.spacing(2.5),
          height: theme.spacing(2.5),
          ...theme.typography.caption,
        },
        '.MuiBox-root': {
          overflow: 'hidden',
          backgroundColor: theme.palette.common.lightBg,
          borderRadius: theme.spacing(0, 0, 1, 1),
          px: 1,
          pb: 1,
        },
        '.header': {
          pt: 1,
          px: 1,
          overflow: 'hidden',
          backgroundColor: theme.palette.common.lightBg,
          borderRadius: theme.spacing(1, 1, 0, 0),
          color: theme.palette.common.grey,
        },
      })}
    >
      <Typography className="header">{formatMessage({ id: 'userType-student-plural' })}</Typography>
      <Typography className="header">{formatMessage({ id: 'singleInvoices-Payers' })}</Typography>
      <Typography className="header">{formatMessage({ id: 'legalEntities-Title' })}</Typography>

      <Box>
        {assignments.map((a) => {
          return (
            <Stack key={a.student.relation_id} direction="row" gap={1} alignItems="center">
              <AvatarAuth user={a.student} />
              <TypographyWithOverflowHint>
                <Typography display="inline" color="primary.main" variant="h3">
                  {getUserFullName(a.student)}
                </Typography>
              </TypographyWithOverflowHint>
            </Stack>
          );
        })}
      </Box>

      <Box>
        {uniquePayers.map((p) => (
          <PayerContent key={getPayerId(p)} isSelected payer={p} />
        ))}
      </Box>

      <Box>
        {Object.entries(legalEntities).map(([id, name]) => {
          return (
            <Typography key={id} variant="h3">
              {name}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

type ParentInviteNotificationProps = {
  schoolId: string;
  guardian: Guardian;
};

const ParentInviteNotification: FC<ParentInviteNotificationProps> = ({ guardian, schoolId }) => {
  const [notificationShowed, , hideNotification] = useFlag(
    guardian.invite_status !== SchoolInviteStatus.Active,
  );

  const { formatMessage } = useIntl();

  if (!notificationShowed) return null;
  return (
    <InviteWarning
      schoolId={schoolId}
      guardian={guardian}
      onHide={hideNotification}
      warningText={formatMessage(
        {
          id:
            guardian.invite_status === SchoolInviteStatus.Invited
              ? 'singleInvoices-NotAcceptedInvite'
              : 'singleInvoices-NotInvited',
        },
        { name: getUserFullName(guardian) },
      )}
    />
  );
};
