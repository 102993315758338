import { Icon, Typography } from '@mui/material';
import { AssignedProductAvailableVariant } from '@schooly/api';
import {
  DropdownSelect,
  DropdownSelectProps,
  DropdownSelectRow,
} from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import { ArrowDownV2Icon } from '@schooly/style';
import { FC, useCallback, useRef } from 'react';

type ProductTypeSelectProps = {
  selectedTypeName: string;
  options: AssignedProductAvailableVariant[];
  onSelect: (v: AssignedProductAvailableVariant) => void;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const ProductTypeSelect: FC<ProductTypeSelectProps> = ({
  selectedTypeName,
  options,
  onSelect,
  ...dropdownProps
}) => {
  const dropdown = useRef<DropdownSelect | null>(null);

  const [opened, open, close] = useFlag();

  const renderContent = useCallback(() => {
    return (
      <>
        {options.map((o) => (
          <DropdownSelectRow
            key={o.type_id}
            isSelected={selectedTypeName === o.type_name}
            onClick={() => {
              onSelect(o);
              dropdown.current?.close();
            }}
          >
            {o.type_name}
          </DropdownSelectRow>
        ))}
      </>
    );
  }, [onSelect, options, selectedTypeName]);

  return (
    <DropdownSelect
      ref={dropdown}
      sx={(theme) => ({
        height: 46,
        width: opened ? 200 : undefined,
        borderRadius: 0,
        margin: theme.spacing('-1px', 0),
        backgroundColor: opened ? undefined : 'transparent',
        borderColor: opened ? undefined : 'transparent',
        outlineColor: 'transparent !important',
        ' .right-icon': {
          right: theme.spacing(1),
        },
      })}
      layoutStyleProps={{ paddingRight: 3 }}
      hasValues
      renderContent={renderContent}
      onOpen={open}
      onClose={close}
      disabled={options.length === 1}
      renderRightIcon={
        options.length === 1
          ? () => <></>
          : () => (
              <Icon
                sx={(theme) => ({
                  width: theme.spacing(1),
                  transform: 'rotate(180deg)',
                  path: { stroke: theme.palette.common.grey },
                })}
              >
                <ArrowDownV2Icon />
              </Icon>
            )
      }
      {...dropdownProps}
    >
      {() => {
        return (
          <Typography color="text.primary" noWrap>
            {selectedTypeName}
          </Typography>
        );
      }}
    </DropdownSelect>
  );
};
