import { IconButton, Stack, Typography } from '@mui/material';
import {
  PaymentFrequency,
  Product,
  ProductType,
  SchoolYear,
  useGetSchoolPaymentFrequencies,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { Counter, DropdownYears, EditIcon, EmptySchoolSvg } from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import useSchoolYears from '../../../../hooks/useSchoolYears';
import { EmptyTypes } from '../EmptyTypes';
import {
  getCurrencySymbol,
  getSortedFrequencies,
  getTypesByYears,
  useProductFrequency,
  useSchoolYearsInProduct,
} from '../helpers';
import { AllFrequenciesNotInUse, ProductTypeLayout } from '../SchoolProductCommon';
import { AccountSeparator } from '../SchoolProductCreateModal/AccountSelect';
import { PreviewVariantsTable } from './PreviewVariantsTable';

type SchoolProductModalVariantsProps = {
  product: Product;
  schoolId: string;
  onEdit?: (yearId?: SchoolYear['id']) => void;
};

export const SchoolProductModalVariants: FC<SchoolProductModalVariantsProps> = ({
  product,
  schoolId,
  onEdit,
}) => {
  const { $t } = useIntl();
  const { schoolYears, defaultValidity } = useSchoolYears();
  // TR-6100 Invoices. Frequencies(Cut) To be used later
  // const [showAllFrequencies, setShowAllFrequencies] = useState(false);
  const { permissions } = useAuth();

  const canViewFrequency = permissions.includes('product_and_invoice_viewer');

  const { data } = useGetSchoolPaymentFrequencies(
    { school_id: schoolId, year_id: defaultValidity?.id ?? '' },
    { enabled: !!defaultValidity?.id },
  );

  const frequencies = useMemo(() => {
    return data?.frequencies
      ? getSortedFrequencies(data.frequencies).filter((f) => !!f.in_use)
      : [];
    // TR-6100 Invoices. Frequencies(Cut) To be used later

    // return showAllFrequencies ? frequencies : frequencies?.filter((f) => f.in_use);
  }, [data]);

  const { allTypesNotActive, productTypeNotActiveMap } = useProductFrequency({
    frequencies: frequencies,
    product,
  });

  const { types } = product;
  const typesByYears = useMemo(() => getTypesByYears(types, schoolYears), [schoolYears, types]);

  const canShowYearInSelect = useCallback(
    (year?: SchoolYear) =>
      Boolean(year && (!isDateInPast(year.end) || typesByYears[year.id]?.length)),
    [typesByYears],
  );

  const { selectedYear, defaultYear, setSelectedYear, yearsForSelect } =
    useSchoolYearsInProduct(canShowYearInSelect);
  const selectedYearId = selectedYear?.id ?? '';

  const currentTypes = typesByYears[selectedYearId] ?? [];
  const hasCurrentTypes = Boolean(currentTypes.length);

  //Based on TR-5885 user can only edit products for next year
  const canEdit = onEdit && selectedYearId === defaultYear?.id;

  return (
    <>
      <Stack justifyContent="space-between" flexDirection="row" alignItems="center" mb={2.25}>
        <Stack flexDirection="row" alignItems="center">
          <Typography variant="h2">
            {$t({ id: 'products-Types' }, { productName: product.name })}
          </Typography>
          {hasCurrentTypes && (
            <Stack direction="row" gap={1}>
              <Counter sx={{ minWidth: 20, minHeight: 20, textAlign: 'center' }}>
                {currentTypes.length}
              </Counter>
              {allTypesNotActive && (
                <AllFrequenciesNotInUse name={$t({ id: 'products-Product' })} />
              )}
            </Stack>
          )}
        </Stack>
        <Stack gap={2.5} direction="row" alignItems="baseline">
          {/* TR-6100 Invoices. Frequencies(Cut) To be used later */}
          {/* {hasCurrentTypes && (
            <FormControlLabel
              sx={{ ml: 0, mt: 1 }}
              control={
                <Switch
                  checked={showAllFrequencies}
                  onChange={() => setShowAllFrequencies((v) => !v)}
                />
              }
              label={$t({ id: 'frequencies-ShowDisabledFrequencies' })}
            />
          )} */}
          <DropdownYears
            years={yearsForSelect}
            defaultYear={defaultYear}
            currentYear={selectedYear}
            onYearChange={setSelectedYear}
          />
          {!!canEdit && (
            <IconButton
              inverse
              onClick={() => onEdit(selectedYearId)}
              sx={{ color: 'common.grey2' }}
            >
              <EditIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
      {hasCurrentTypes ? (
        currentTypes.map((t) => (
          <ProductTypeVariants
            schoolId={schoolId}
            type={t}
            key={t.id}
            frequencies={frequencies}
            canViewFrequency={canViewFrequency}
            notActive={allTypesNotActive ? false : productTypeNotActiveMap[t.id]}
          />
        ))
      ) : (
        <EmptyTypes
          svg={<EmptySchoolSvg />}
          onAdd={canEdit ? () => onEdit(selectedYearId) : undefined}
          messageId={'products-NoTypes'}
          productName={product.name}
        />
      )}
    </>
  );
};

type ProductTypeVariantsProps = {
  schoolId: string;
  type: ProductType;
  frequencies: PaymentFrequency[];
  canViewFrequency: boolean;
  notActive: boolean;
};

const ProductTypeVariants: FC<ProductTypeVariantsProps> = ({
  type,
  schoolId,
  frequencies,
  canViewFrequency,
  notActive,
}) => {
  const { variants, billing_connection } = type;

  const currencySymbol = getCurrencySymbol(billing_connection.legal_entity_currency);

  const variantsFrequencies = useMemo(
    () =>
      frequencies
        .filter((fr) => variants.some((v) => v.prices.some((p) => p.frequency_id === fr?.id)))
        .filter(Boolean),
    [frequencies, variants],
  );

  const { $t } = useIntl();

  return (
    <Stack>
      <ProductTypeLayout>
        <Stack direction="row" alignItems="center" width={472} gap={1}>
          <Typography variant="h2">{type.name}</Typography>
          {notActive && (
            <AllFrequenciesNotInUse name={`${$t({ id: 'products-Type' })} ${type.name}`} />
          )}
        </Stack>
        <Stack alignSelf="center" direction="row" gap={1}>
          <Typography variant="h3"> {billing_connection.legal_entity_display_name}</Typography>
          {billing_connection.legal_entity_account.type === 'xero' && (
            <Stack direction="row" alignItems="baseline">
              <AccountSeparator selected />
              <Typography variant="h3">{billing_connection.legal_entity_account.name}</Typography>
            </Stack>
          )}
        </Stack>
      </ProductTypeLayout>

      <Stack
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.spacing(1),
          mb: theme.spacing(2.5),
        })}
      >
        <PreviewVariantsTable
          addedFrequencies={variantsFrequencies}
          canViewFrequency={canViewFrequency}
          schoolId={schoolId}
          variants={variants}
          currencySymbol={currencySymbol}
        />
      </Stack>
    </Stack>
  );
};
