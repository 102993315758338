import { Box, Icon, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { PaymentFrequency, ProductFormType } from '@schooly/api';
import {
  CrossIcon,
  DeleteIcon,
  Grid,
  GridBody,
  GridCell,
  GridHead,
  GridRow,
  LockIcon,
  PlusIcon,
  PRICE_SUBTEXT_CLASS_NAME,
  SimpleButton,
} from '@schooly/style';
import React, { FC, Fragment, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import { useTableHover } from '../../../../context/table/tableHover/useTableHover';
import {
  HOVER_CLASS_NAME,
  SELF_HOVER_CLASS_NAME,
  WithTableHover,
} from '../../../../context/table/tableHover/WithTableHover';
import { FrequenciesTooltip, NotInUseFrequencyLabel } from '../SchoolProductCommon';
import { ProductPriceInput } from './ProductPriceInput';
import { IntersectionIds } from './SchoolProductCreateModalContent';
import {
  ApplicableToForm,
  FrequenciesPriceForm,
  HalfDayForm,
} from './SchoolProductCreateModalVariants';

export const HOVER_TEXT_CLASS_NAME = 'hoverText';

type CreateVariantsTableProps = {
  addedFrequencies: PaymentFrequency[];
  typeIdx: number;
  schoolId: string;
  intersectionIds: IntersectionIds;
  currencySymbol: string;
  canViewFrequency: boolean;
  canRemoveFrequency: boolean;
  onToggleFrequency: (f: PaymentFrequency) => void;
  availableFrequencies: PaymentFrequency[];
  onRemoveVariant?: (vIdx: number) => void;
  variants: ProductFormType['variants'];
};

export type BaseCellType = 'Half day' | 'Applicable to';

export type Cell =
  | {
      type: BaseCellType;
    }
  | PaymentFrequency;

export type EditableCell = {
  type: BaseCellType | 'Frequencies';
};

export const variantBaseCells: BaseCellType[] = ['Half day', 'Applicable to'];

export const CreateVariantsTable: FC<CreateVariantsTableProps> = ({
  canRemoveFrequency,
  canViewFrequency,
  onToggleFrequency,
  availableFrequencies,
  ...props
}) => {
  const theme = useTheme();
  const headerCells: Cell[] = [
    ...variantBaseCells.map((c) => ({ type: c })),
    ...props.addedFrequencies,
  ];

  const hoverStyles = {
    [`.${HOVER_CLASS_NAME}`]: {
      backgroundColor: `${theme.palette.background.default} !important`,
      [`.${HOVER_TEXT_CLASS_NAME}, .${PRICE_SUBTEXT_CLASS_NAME}`]: {
        color: theme.palette.text.primary,
      },

      '&.MuiTableCell-body .MuiIconButton-root': {
        color: theme.palette.primary.main,
      },
    },
    [`.${SELF_HOVER_CLASS_NAME}`]: {
      backgroundColor: `${theme.palette.common.grey7} !important`,
      [`.${HOVER_TEXT_CLASS_NAME}, .${PRICE_SUBTEXT_CLASS_NAME}`]: {
        color: theme.palette.text.primary,
      },
    },
  };

  return (
    <WithTableHover hoverStyles={hoverStyles}>
      <Grid
        sx={(theme) => ({
          '.MuiTableCell-root:not(:last-child)': {
            borderRight: theme.mixins.borderValue(),
          },
        })}
      >
        <VariantsTableHeader
          cells={headerCells}
          canRemoveFrequency={canRemoveFrequency}
          canViewFrequency={canViewFrequency}
          onToggleFrequency={onToggleFrequency}
        >
          <GridCell noVerticalPadding width={44}>
            {!!availableFrequencies.length && (
              <AddFrequency
                availableFrequencies={availableFrequencies}
                onToggleFrequency={onToggleFrequency}
              />
            )}
          </GridCell>
        </VariantsTableHeader>
        <EditVariantsTableBody {...props} />
      </Grid>
    </WithTableHover>
  );
};

type EditVariantsTableBodyProps = {
  variants: ProductFormType['variants'];
  addedFrequencies: PaymentFrequency[];
  intersectionIds: IntersectionIds;
  currencySymbol: string;
  schoolId: string;
  typeIdx: number;
  onRemoveVariant?: (vIdx: number) => void;
};

const EditVariantsTableBody: FC<EditVariantsTableBodyProps> = ({
  variants,
  onRemoveVariant,
  ...props
}) => {
  const { gridRef, styles, getRowHoverProps } = useTableHover();

  const cells: EditableCell[] = [
    ...variantBaseCells.map((c) => ({ type: c })),
    { type: 'Frequencies' },
  ];

  return (
    <GridBody ref={gridRef} sx={{ ...styles }}>
      {variants.map((v, variantIdx) => (
        <EditVariantRow key={v.id} variantCells={cells} variantIdx={variantIdx} {...props}>
          <GridCell
            noPadding
            {...getRowHoverProps({
              cellId: 'action',
              colIndex: cells.length + props.addedFrequencies.length,
              rowIndex: variantIdx,
              onlyXHover: true,
            })}
          >
            {onRemoveVariant && (
              <Stack>
                <IconButton inverse onClick={() => onRemoveVariant(variantIdx)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            )}
          </GridCell>
        </EditVariantRow>
      ))}
    </GridBody>
  );
};

type EditVariantRowProps = {
  variantCells: EditableCell[];
  typeIdx: number;
  variantIdx: number;
  schoolId: string;
  intersectionIds: IntersectionIds;
  addedFrequencies: PaymentFrequency[];
  currencySymbol: string;
};

const EditVariantRow: FC<PropsWithChildren<EditVariantRowProps>> = ({
  variantCells,
  typeIdx,
  variantIdx,
  schoolId,
  intersectionIds,
  addedFrequencies,
  children,
  currencySymbol,
}) => {
  const { getRowHoverProps } = useTableHover();

  const renderCell = (c: EditableCell, colIndex: number) => {
    const props = getRowHoverProps({
      cellId: c.type,
      colIndex,
      rowIndex: variantIdx,
      onlyXHover: c.type === 'Applicable to',
    });
    switch (c.type) {
      case 'Half day':
        return (
          <GridCell noPadding borderRight {...props}>
            <HalfDayForm variantIdx={variantIdx} typeIdx={typeIdx} />
          </GridCell>
        );
      case 'Applicable to':
        return (
          <GridCell borderRight noPadding sx={{ position: 'relative' }} {...props}>
            <ApplicableToForm
              schoolId={schoolId}
              typeIdx={typeIdx}
              variantIdx={variantIdx}
              intersectionIds={intersectionIds}
              sx={{
                border: 'none',
                '.header': {
                  minHeight: 44,
                },
              }}
              renderError={() => (
                <Box
                  sx={(theme) => ({
                    pointerEvents: 'none',
                    position: 'absolute',
                    minHeight: 43,
                    left: -1,
                    top: 0,
                    right: -1,
                    bottom: 0,
                    border: `1px solid ${theme.palette.error.main}`,
                  })}
                />
              )}
            />
          </GridCell>
        );

      case 'Frequencies':
        return (
          <FrequenciesPriceForm
            currencySymbol={currencySymbol}
            frequencies={addedFrequencies}
            typeIndex={typeIdx}
            variantIdx={variantIdx}
          >
            {(field, hasError) =>
              addedFrequencies.map((f, i, arr) => {
                const relatedPrice = field.value.find((p) => p.frequency_id === f.id);

                const freqColIdx = variantCells.length - 1 + i;
                const frequencyHoverProps = getRowHoverProps({
                  cellId: f.type,
                  colIndex: freqColIdx,
                  rowIndex: variantIdx,
                });

                return (
                  <GridCell
                    key={f.id}
                    borderRight={!hasError}
                    {...frequencyHoverProps}
                    sx={(theme) => {
                      const errorBorder = `1px solid ${theme.palette.error.main} !important`;
                      return {
                        overflow: 'hidden',
                        ...(hasError && {
                          '&.MuiTableCell-root': {
                            borderTop: errorBorder,
                            borderBottom: errorBorder,
                            ...(i === 0 && { borderLeft: errorBorder }),
                            ...(i === arr.length - 1 && { borderRight: errorBorder }),
                          },
                        }),
                      };
                    }}
                    noPadding
                  >
                    <ProductPriceInput
                      currencySymbol={currencySymbol}
                      value={relatedPrice?.price}
                      onChange={(price) => {
                        field.onChange([
                          ...field.value.filter((p) => p.frequency_id !== f.id),
                          ...(price ? [{ frequency_id: f.id, price }] : []),
                        ]);
                      }}
                    />
                  </GridCell>
                );
              })
            }
          </FrequenciesPriceForm>
        );

      default:
        const type: never = c.type;

        console.error(`Unexpected type ${type}`);
    }
  };

  return (
    <GridRow withoutDefaultHover>
      {variantCells.map((c, idx) => (
        <Fragment key={c.type}>{renderCell(c, idx)}</Fragment>
      ))}
      {children}
    </GridRow>
  );
};

type OntoggleFrequency = (f: PaymentFrequency) => void;

type VariantsTableHeaderProps = {
  cells: Cell[];
  canRemoveFrequency: boolean;
  canViewFrequency: boolean;
  onToggleFrequency?: OntoggleFrequency;
};

export const VariantsTableHeader: FC<PropsWithChildren<VariantsTableHeaderProps>> = ({
  cells,
  canRemoveFrequency,
  canViewFrequency,
  onToggleFrequency,
  children,
}) => {
  const { $t } = useIntl();
  const { columnRef, getColumnHoverProps, styles } = useTableHover();

  const renderCell = (cell: Cell, idx: number) => {
    const props = getColumnHoverProps({
      cellId: cell.type,
      colIndex: idx,
      rowIndex: 0,
    });

    switch (cell.type) {
      case 'Half day':
        return (
          <GridCell width={60} {...props}>
            <Typography noWrap className={HOVER_TEXT_CLASS_NAME}>
              {$t({ id: 'students-HalfDay' })}
            </Typography>
          </GridCell>
        );

      case 'Applicable to':
        return (
          <GridCell {...props}>
            <Typography noWrap className={HOVER_TEXT_CLASS_NAME}>
              {$t({ id: 'products-TheProductIsApplicableTo' })}
            </Typography>
          </GridCell>
        );

      default:
        return (
          <GridCell
            sx={{
              ':hover .removeIcon': {
                visibility: 'visible',
                padding: 0,
              },
              width: 120,
            }}
            {...props}
          >
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Typography noWrap className={HOVER_TEXT_CLASS_NAME}>
                {$t({ id: `frequencies-${cell.type}` })}
              </Typography>
              <Stack>
                {!cell.in_use && (
                  <FrequenciesTooltip
                    title={<NotInUseFrequencyLabel frequency={cell} canView={canViewFrequency} />}
                  >
                    <Icon inverse fontSize="small">
                      <LockIcon />
                    </Icon>
                  </FrequenciesTooltip>
                )}
                {canRemoveFrequency && cell.in_use && (
                  <IconButton
                    inverse
                    size="small"
                    className="removeIcon"
                    sx={{
                      visibility: 'hidden',
                    }}
                    onClick={() => onToggleFrequency?.(cell)}
                  >
                    <CrossIcon />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          </GridCell>
        );
    }
  };

  return (
    <GridHead
      borderBottom
      sx={{
        '& .MuiTableCell-root': {
          height: '34px !important',
        },
        '&& .MuiTableCell-head': {
          py: 0,
        },
        ...styles,
      }}
    >
      <GridRow ref={columnRef} withoutDefaultHover>
        {cells.map((c, i) => (
          <Fragment key={c.type}>{renderCell(c, i)}</Fragment>
        ))}
        {children}
      </GridRow>
    </GridHead>
  );
};

type AddFrequencyProps = {
  availableFrequencies: PaymentFrequency[];
  onToggleFrequency: (f: PaymentFrequency) => void;
};

const AddFrequency: FC<AddFrequencyProps> = ({ availableFrequencies, onToggleFrequency }) => {
  const { $t } = useIntl();

  return (
    <Tooltip
      title={
        <Stack gap={1} alignItems="flex-start" width={200}>
          {availableFrequencies.map((fr) => (
            <SimpleButton
              key={fr.id}
              startIcon={<PlusIcon />}
              onClick={() => onToggleFrequency(fr)}
            >
              {$t(
                { id: 'products-AddFrequency' },
                { frequency: $t({ id: `frequencies-${fr.type}` }) },
              )}
            </SimpleButton>
          ))}
        </Stack>
      }
      disableFocusListener
      disableTouchListener
    >
      <IconButton sx={{ pl: 0.25 }} inverse>
        <PlusIcon />
      </IconButton>
    </Tooltip>
  );
};
