import {
  Box,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AssignedProductAvailableVariant,
  AssignedProductWithAvailableVariants,
  PayerType,
  ProductVariantPrice,
} from '@schooly/api';
import {
  ArrowDownV2Icon,
  CircleIconButton,
  CursorCloseHandIcon,
  DragIcon,
  Grid,
  GridBody,
  GridCell,
  GridHead,
  LockIcon,
  MinusIcon,
  Price,
  PRICE_SUBTEXT_CLASS_NAME,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, RefObject, useCallback, useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useFieldArray, UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { PlusIcon } from '../../../../../components/ui/Icons';
import { getCurrencySymbol } from '../../../../../pages/School/SchoolProducts/helpers';
import { DiscountInput } from './DiscountInput';
import { getDiscountedPrice } from './helpers';
import { ProductTypeSelect } from './ProductTypeSelect';
import {
  handleUpdateProductProps,
  UpdateStudentProductsForm,
  WithIndex,
} from './StudentProductsModalContent';
import { VariantPriceSelect } from './VariantPriceSelect';

export const HOVER_TEXT_CLASS_NAME = 'hoverText';
const HOVER_ICON_CLASS_NAME = 'hoverIcon';
const ASSIGNED_PRODUCTS_DND_TYPE = 'ASSIGNED_PRODUCTS_DND_TYPE';

type StudentProductDragItem = AssignedProductWithAvailableVariants & WithIndex;

type StudentProductsModalListProps = {
  relationId: string;
  payerType: PayerType;
  products: (AssignedProductWithAvailableVariants & WithIndex)[];
  schoolId: string;
  form: UseFormReturn<UpdateStudentProductsForm>;
  yearId: string;
  onUpdateProduct: (p: handleUpdateProductProps) => void;
  missingFrequencyId: string;
  missingFrequencyForVariantId: Set<string>;
};

export const StudentProductsModalList: FC<StudentProductsModalListProps> = ({
  form,
  schoolId,
  payerType,
  products,
  yearId,
  onUpdateProduct,
  missingFrequencyId,
  missingFrequencyForVariantId,
}) => {
  const gridRef = useRef<HTMLTableElement>(null);
  const productsTotal = form.watch('products').length;

  const { remove, move } = useFieldArray({
    control: form.control,
    name: 'products',
  });

  const handleProductPayerChange = useCallback(
    (index: number) => onUpdateProduct({ index, productUpdate: { payer_type: payerType } }),
    [onUpdateProduct, payerType],
  );

  const scrollOnDrop = useCallback(() => {
    const gridElement = gridRef.current;
    if (gridElement) {
      const { bottom } = gridElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight - 60;
      if (bottom > viewportHeight) {
        gridElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  }, []);

  const onDrop = useCallback(
    (item: StudentProductDragItem) => {
      if (item.payer_type !== payerType) {
        handleProductPayerChange(item.index);
        move(item.index, productsTotal - 1);
        scrollOnDrop();
      }
    },
    [handleProductPayerChange, move, payerType, productsTotal, scrollOnDrop],
  );

  const [, drop] = useDrop(
    useMemo(() => ({ accept: ASSIGNED_PRODUCTS_DND_TYPE, drop: onDrop }), [onDrop]),
  );

  if (!products.length) return <StudentProductsEmptyList onDrop={onDrop} payerType={payerType} />;

  return (
    <Grid stickyHeader fixedLayout ref={gridRef}>
      <StudentProductsHeader />

      <GridBody ref={drop}>
        {products.map((product, i, arr) => {
          const sameProducts = products.filter((p) => p.id === product.id);

          return (
            <StudentProductRow
              key={product.id + product.variant.id}
              showMissingFrequencyId={
                missingFrequencyForVariantId.has(product.variant.id)
                  ? missingFrequencyId
                  : undefined
              }
              schoolId={schoolId}
              product={product}
              otherAddedTypes={sameProducts
                .map((p) => p.variant.type_name)
                .filter((typeName) => typeName !== product.variant.type_name)}
              onDelete={!product.obligatory || sameProducts.length > 1 ? remove : undefined}
              onUpdate={onUpdateProduct}
              yearId={yearId}
            />
          );
        })}
      </GridBody>
    </Grid>
  );
};

export const StudentProductsHeader: FC = () => {
  const { $t } = useIntl();

  return (
    <GridHead sx={{ '& .MuiTableCell-root.MuiTableCell-head': { height: 34 } }} borderBottom>
      <GridCell>{$t({ id: 'products-Name' })} </GridCell>
      <GridCell
        width="110px"
        sx={(theme) => ({
          '&.MuiTableCell-head.MuiTableCell-root': { paddingLeft: 1.5 },
          [theme.breakpoints.only('lg')]: { width: '100px' },
        })}
      >
        {$t({ id: 'products-Type' })}
      </GridCell>
      <GridCell
        width="110px"
        sx={(theme) => ({
          '&.MuiTableCell-head.MuiTableCell-root': { paddingLeft: 1.5 },
          [theme.breakpoints.only('lg')]: { width: '100px' },
        })}
      >
        {$t({ id: 'frequencies-Frequency' })}
      </GridCell>
      <GridCell
        width="100px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: { width: '90px' },
        })}
      >
        {$t({ id: 'products-Price' })}
      </GridCell>
      <GridCell width="45px">{$t({ id: 'products-Dis' })}</GridCell>
      <GridCell
        width="100px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: { width: '90px' },
        })}
      >
        {$t({ id: 'products-Amount' })}
      </GridCell>
      <GridCell width="30px" />
    </GridHead>
  );
};

type StudentProductRowProps = {
  schoolId: string;
  product: AssignedProductWithAvailableVariants & WithIndex;
  showMissingFrequencyId?: string;
  otherAddedTypes: string[];
  onDelete?: (index: number) => void;
  yearId: string;
  onUpdate: (p: handleUpdateProductProps) => void;
  ref?: RefObject<HTMLDivElement | undefined>;
};

const StudentProductRow: FC<StudentProductRowProps> = ({
  product,
  schoolId,
  showMissingFrequencyId,
  otherAddedTypes,
  onDelete,
  onUpdate,
  yearId,
}) => {
  const { $t } = useIntl();
  const { index } = product;

  const [, drag, preview] = useDrag(
    useMemo(
      () => ({
        type: ASSIGNED_PRODUCTS_DND_TYPE,
        item: product,
      }),
      [product],
    ),
  );

  const getRowStyles = (theme: Theme) => ({
    '&:hover': {
      'td.MuiTableCell-root': {
        backgroundColor: theme.palette.background.default,
        '.MuiTypography-root': {
          color: theme.palette.primary.main,
        },
        [`.${HOVER_ICON_CLASS_NAME}`]: {
          color: theme.palette.primary.main,
        },
        [`.${PRICE_SUBTEXT_CLASS_NAME}, .${HOVER_TEXT_CLASS_NAME}`]: {
          color: theme.palette.common.grey2,
        },
        ' input': {
          color: theme.palette.primary.main,
        },
      },
    },
  });

  const { handleProductPriceChange, handleProductDiscountChange, handleTypeChange } = useMemo(
    () => ({
      handleProductPriceChange: (price: ProductVariantPrice) => {
        onUpdate({
          index,
          productUpdate: { variant: { frequency_id: price.frequency_id } },
        });
      },
      handleProductDiscountChange: (discount?: number) => {
        onUpdate({ index, productUpdate: { discount_percent: discount } });
      },
      handleTypeChange: (variant: AssignedProductAvailableVariant) => {
        const hasSameFrequency = variant.prices.some(
          (p) => p.frequency_id === product.variant.frequency_id,
        );

        onUpdate({
          index,
          productUpdate: {
            variant: {
              id: variant.id,
              type_name: variant.type_name,
              frequency_id: hasSameFrequency
                ? product.variant.frequency_id
                : variant.prices[0].frequency_id,
            },
          },
        });
      },
    }),
    [index, onUpdate, product.variant.frequency_id],
  );

  const { price, variant } = useMemo(() => {
    const variant = product.available_variants.find((v) => v.id === product.variant.id);
    const price = variant?.prices.find(
      (p) => p.frequency_id === product.variant.frequency_id,
    )?.price;

    return {
      price,
      variant,
    };
  }, [product.available_variants, product.variant.id, product.variant.frequency_id]);

  const typeOptions = useMemo(
    () => product.available_variants.filter((v) => !otherAddedTypes.includes(v.type_name)),
    [otherAddedTypes, product.available_variants],
  );

  const skeleton = (
    <Typography>
      <Skeleton variant="text" />
    </Typography>
  );

  if (!variant)
    return (
      <TableRow>
        {[...new Array(7)].map((_, i) => (
          <GridCell key={i}>{skeleton}</GridCell>
        ))}
      </TableRow>
    );

  const variantSelectDisabled = variant.prices.length < 2;

  const currencySymbol = getCurrencySymbol(variant.currency);

  return (
    <TableRow sx={getRowStyles} ref={preview}>
      <GridCell noVerticalPadding>
        <Stack direction="row" gap={1}>
          <IconButton inverse className={HOVER_ICON_CLASS_NAME} ref={drag}>
            <DragIcon />
          </IconButton>
          <TypographyWithOverflowHint
            variant="h3"
            color="text.primary"
            component={Link}
            to={`/settings/products/${product.id}`}
            replace
            sx={{ '&:hover': { textDecoration: 'underline' } }}
          >
            {product.name}
          </TypographyWithOverflowHint>
        </Stack>
      </GridCell>
      <GridCell noPadding>
        <ProductTypeSelect
          selectedTypeName={product.variant.type_name || '-'}
          options={typeOptions}
          onSelect={handleTypeChange}
          maxExpandedContainerWidth={200}
        />
      </GridCell>
      <GridCell noPadding>
        <VariantPriceSelect
          schoolId={schoolId}
          options={variant.prices}
          priceFrequencyId={product.variant.frequency_id}
          showMissingFrequencyId={!variantSelectDisabled ? showMissingFrequencyId : undefined}
          onSelect={handleProductPriceChange}
          disabled={variantSelectDisabled}
          renderRightIcon={
            variantSelectDisabled
              ? () => <></>
              : () => (
                  <Icon
                    sx={(theme) => ({
                      width: theme.spacing(1),
                      transform: 'rotate(180deg)',
                      path: { stroke: theme.palette.common.grey },
                    })}
                  >
                    <ArrowDownV2Icon />
                  </Icon>
                )
          }
          maxExpandedContainerWidth={200}
          yearId={yearId}
        />
      </GridCell>
      <GridCell noVerticalPadding>
        {price ? (
          <TypographyWithOverflowHint>
            <Price currency={currencySymbol} variant="body1" color="text.primary" price={price} />
          </TypographyWithOverflowHint>
        ) : (
          '-'
        )}
      </GridCell>
      <GridCell noPadding>
        <DiscountInput value={product.discount_percent} onChange={handleProductDiscountChange} />
      </GridCell>

      <GridCell noVerticalPadding>
        {price ? (
          <TypographyWithOverflowHint>
            <Price
              currency={currencySymbol}
              variant="body1"
              color="text.primary"
              price={
                product.discount_percent
                  ? getDiscountedPrice(price, product.discount_percent)
                  : price
              }
            />
          </TypographyWithOverflowHint>
        ) : (
          '-'
        )}
      </GridCell>

      <GridCell
        noVerticalPadding
        onClick={
          onDelete
            ? (e) => {
                e.preventDefault();
                onDelete?.(index);
              }
            : undefined
        }
      >
        <Stack alignItems="center">
          {!onDelete ? (
            <Tooltip
              title={$t({ id: 'products-ProductIsRequired' })}
              componentsProps={{
                tooltip: {
                  sx: (theme) => ({
                    padding: theme.spacing(1.25),
                  }),
                },
              }}
            >
              <Icon className={HOVER_ICON_CLASS_NAME} sx={{ color: 'common.grey' }}>
                <LockIcon />
              </Icon>
            </Tooltip>
          ) : (
            <CircleIconButton sx={(theme) => ({ fontSize: theme.spacing(1.75) })}>
              <MinusIcon />
            </CircleIconButton>
          )}
        </Stack>
      </GridCell>
    </TableRow>
  );
};

type StudentProductsEmptyListProps = {
  onDrop: (item: StudentProductDragItem) => void;
  payerType: PayerType;
};

const StudentProductsEmptyList: FC<StudentProductsEmptyListProps> = ({ onDrop, payerType }) => {
  const { formatMessage } = useIntl();

  const [, drop] = useDrop(
    useMemo(() => ({ accept: ASSIGNED_PRODUCTS_DND_TYPE, drop: onDrop }), [onDrop]),
  );

  switch (payerType) {
    case PayerType.Default:
      return (
        <Stack
          ref={drop}
          justifyContent="center"
          alignItems="center"
          direction="row"
          gap={1}
          p={3.25}
          sx={(theme) => ({ borderRadius: theme.spacing(1), bgcolor: 'background.default' })}
        >
          <Typography textAlign="center" variant="h3">
            {formatMessage({ id: 'profile-PressToAddProducts-1' })}
          </Typography>
          <CircleIconButton
            disabled
            sx={(theme) => ({
              '&.Mui-disabled': {
                color: theme.palette.background.paper,
                background: theme.palette.primary.main,
              },
            })}
          >
            <PlusIcon />
          </CircleIconButton>
          <Typography textAlign="center" variant="h3">
            {formatMessage({ id: 'profile-PressToAddProducts-2' })}
          </Typography>
        </Stack>
      );

    case PayerType.Company:
      return (
        <Stack
          ref={drop}
          justifyContent="center"
          alignItems="center"
          direction="row"
          gap={1}
          p={5.25}
          sx={(theme) => ({
            borderRadius: theme.spacing(1),
            bgcolor: theme.palette.background.paper,
            border: `5px dashed ${theme.palette.common.grey3}`,
            outline: `5px solid ${theme.palette.background.paper}`,
            outlineOffset: '-6px',
          })}
        >
          <Typography textAlign="center" variant="h3">
            {formatMessage({ id: 'profile-UseDragAndDrop-1' })}
          </Typography>

          <Stack
            direction="row"
            gap={1}
            sx={(theme) => ({
              padding: theme.spacing(1),
              paddingLeft: theme.spacing(0.5),
              borderRadius: theme.spacing(0.5),
              boxShadow: '0px 10px 40px 0px #25275A26',
              position: 'relative',
            })}
          >
            <Icon sx={{ color: 'primary.main' }}>
              <DragIcon />
            </Icon>
            <Icon sx={{ position: 'absolute', left: 15, top: 15 }}>
              <CursorCloseHandIcon />
            </Icon>
            <Box
              sx={(theme) => ({
                bgcolor: theme.palette.background.default,
                height: 20,
                width: 80,
                borderRadius: theme.spacing(0.25),
              })}
            ></Box>
          </Stack>
          <Typography textAlign="center" variant="h3">
            {formatMessage({ id: 'profile-UseDragAndDrop-2' })}
          </Typography>
        </Stack>
      );
  }
};
