import { Box, Stack, Typography } from '@mui/material';
import {
  StudentForCompany,
  useGetSchoolPaymentFrequencies,
  useGetStudentsForCompanyListQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Counter, ModalSearch, PlusIcon, SimpleButton } from '@schooly/style';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { useCompany } from '../../../context/companies/WithCompany';
import useSchoolYears from '../../../hooks/useSchoolYears';
import {
  StudentWithProducts,
  StudentWithProductsSkeleton,
} from '../../ProfileModal/tabs/ProfileModalAssignedProductsList/StudentWithProducts';
import {} from '../../ProfileModal/tabs/ProfileModalDependants';
import { STUDENT_PRODUCTS_COMPANY_ID_PARAM } from '../../ProfileModal/tabs/ProfileModalPayers/StudentProductsModal/StudentProductsModal';
import { CompanyPreviewModalEmptyStub } from './CompanyPreviewModalEmptyStub';

export const CompanyPreviewModalStudents = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { schoolId = '', permissions } = useAuth();
  const { defaultValidity, isLoading: isYearsFetching } = useSchoolYears();
  const { id: companyId, canEditCompanyStudents } = useCompany();

  const canEdit = permissions.includes('payer_and_product_assignment_manager');

  const navigateToAddStudent = useCallback(
    () => navigate(`/companies/${companyId}/add-student`),
    [companyId, navigate],
  );

  const navigateToProductAssignment = (id: string) =>
    navigate(`/students/${id}/products?${STUDENT_PRODUCTS_COMPANY_ID_PARAM}=${companyId}`);

  const {
    data,
    isLoading,
    isFetching,
    setParams,
    params,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetStudentsForCompanyListQuery(
    {
      query: '',
      companyId,
    },
    { enabled: !!companyId, refetchOnMount: 'always' },
  );

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage, hasNextPage);

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const students = useMemo(
    () =>
      data?.pages.reduce<StudentForCompany[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

    [data?.pages],
  );
  const totalStudents = data?.pages[0].count ?? 0;

  const isEmpty = !isFetching && !isLoading && !totalStudents;

  const { data: frequenciesData } = useGetSchoolPaymentFrequencies(
    {
      school_id: schoolId,
      year_id: defaultValidity?.id ?? '',
    },
    { enabled: Boolean(schoolId && defaultValidity) },
  );

  const renderContent = () => {
    if (isEmpty) {
      return (
        <CompanyPreviewModalEmptyStub
          emptySearchResults={!!params.query}
          onButtonClick={canEditCompanyStudents ? navigateToAddStudent : undefined}
          messageTextId={
            params.query ? 'companies-NoResultsMatching-Student' : 'companies-NoStudents'
          }
          buttonTextId="companies-AddStudent"
        />
      );
    }

    return (
      <Stack>
        {isLoading || isYearsFetching ? (
          <StudentWithProductsSkeleton />
        ) : (
          <Stack gap={4}>
            {students.map((student) => {
              return (
                <StudentWithProducts
                  key={student.relation.id}
                  student={student.relation}
                  products={student.products}
                  frequencies={frequenciesData?.frequencies}
                  onEdit={canEdit ? navigateToProductAssignment : undefined}
                />
              );
            })}
          </Stack>
        )}
        {hasNextPage && (
          <Box py={4}>
            <div ref={loaderRef} />
            <StudentWithProductsSkeleton />
          </Box>
        )}
      </Stack>
    );
  };

  return (
    <Stack height="100%" gap={1.75} marginTop={-0.75}>
      <Stack direction="row" justifyContent="space-between" minHeight={40}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            {formatMessage({ id: 'companies-StudentsAndProducts' })}
          </Typography>
          {!!totalStudents && <Counter>{totalStudents}</Counter>}
        </Stack>

        {!!(totalStudents || params.query) && (
          <Stack direction="row" gap={2.75}>
            <ModalSearch
              value={params.query}
              onChange_MemoizedCallbackOnly={handleSetFiltersQuery}
              placeholder={formatMessage({ id: 'people-Search' })}
              withDebounce
            />
            {canEditCompanyStudents && (
              <SimpleButton inverse startIcon={<PlusIcon />} onClick={navigateToAddStudent}>
                {formatMessage({ id: 'companies-AddStudent' })}
              </SimpleButton>
            )}
          </Stack>
        )}
      </Stack>

      {renderContent()}
    </Stack>
  );
};
