import { Checkbox, FormControlLabel } from '@mui/material';
import { RadioOnIcon } from '@schooly/style';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { RadioOptionCard } from 'apps/web/src/components/uikit-components/FormCheckbox/RadioOptionCard.styled';
import { FC, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

interface RadioGroupCardProps {
  labelTextId: string;
  checked: boolean;
  disabled?: boolean;
  name?: string;
  onChange: () => void;
}

export const RadioGroupCard: FC<PropsWithChildren<RadioGroupCardProps>> = ({
  name,
  checked,
  disabled,
  labelTextId,
  onChange,
  children,
}) => {
  const { $t } = useIntl();

  return (
    <RadioOptionCard
      sx={{ width: '100%', gap: 1 }}
      onClick={!disabled ? onChange : undefined}
      checked={checked}
      disabled={disabled}
    >
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            sx={(theme) => ({ padding: 0, marginRight: theme.spacing(1) })}
            name={name}
            checked={checked}
            onChange={onChange}
            checkedIcon={<RadioOnIcon className="reset-svg-currentColor" />}
          />
        }
        label={$t({ id: labelTextId })}
      />

      {children}
    </RadioOptionCard>
  );
};
