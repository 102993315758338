import { IconButton, Stack } from '@mui/material';
import {
  Company,
  FilterKeys,
  StudentWithProducts,
  useGetStudentsWithProductsListQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import {
  checkHasFiltersApplied,
  PageHeaderSearchInput,
  SearchInputFilterIconButton,
} from '@schooly/components/filters';
import { SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import {
  CrossIcon,
  GridBody,
  MainPageGrid,
  ModalContent,
  ModalHeader,
  ModalMain,
  ModalSmall,
  SkeletonGridLoader,
  SkeletonRows,
} from '@schooly/style';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { AddStudentsFilters } from '../../Conduct/ConductCreateModal/AddStudentsFiltersPopup';
import { InlineFiltersTags } from '../../Conduct/ConductCreateModal/InlineFiltersTags';
import { STUDENT_PRODUCTS_COMPANY_ID_PARAM } from '../../ProfileModal/tabs/ProfileModalPayers/StudentProductsModal/StudentProductsModal';
import {
  AddStudentsToCompanyFilters,
  AddStudentsToCompanyFiltersPopup,
} from './AddStudentsToCompanyFiltersPopup';
import { StudentAddToCompanyRow, StudentsAddToCompanyHeader } from './StudentsAddToCompanyGrid';

export interface HandleStudentSelectProps {
  studentId: string;
  studentName: string;
  companyPayerId?: string;
  companyPayerName?: string;
}

const studentsAddToCompanyFilters: AddStudentsToCompanyFilters = {
  [FilterKeys.Status]: [],
  [FilterKeys.AgeGroup]: [],
};

const PAGE_SIZE = 30;
const SKELETON_COLUMNS = 2;

interface AddStudentsToCompanyModalContentProps {
  onClose: () => void;
  company: Company;
}

export const AddStudentsToCompanyModalContent: FC<AddStudentsToCompanyModalContentProps> = ({
  onClose,
  company,
}) => {
  const { $t } = useIntl();
  const navigate = useNavigate();
  const { schoolId = '' } = useAuth();
  const [filtersModalVisible, openFilterModal, closeFiltersModal] = useFlag(false);
  const { getConfirmation } = useConfirmationDialog();
  const [defaultFiltersInitialized, setDefaultFiltersInitialized] = useState(false);

  const { activePropertiesMap, isLoading: isLoadingProperties } = useSchoolProperties(
    {
      schoolId: schoolId,
      userType: SchoolUserRole.Student,
      showReEnrollmentProperties: true,
    },
    { enabled: !!schoolId, refetchOnMount: 'always' },
  );

  const defaultFilters: AddStudentsToCompanyFilters | undefined = useMemo(() => {
    if (isLoadingProperties) return;
    const statuses = activePropertiesMap.status.filter(({ category }) => !category?.final) ?? [];
    const defaultStatusesIds = statuses.map(({ id }) => id);

    return {
      ...(defaultStatusesIds.length ? { [FilterKeys.Status]: defaultStatusesIds } : {}),
    };
  }, [activePropertiesMap.status, isLoadingProperties]);

  const { data, params, setParams, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useGetStudentsWithProductsListQuery(
      {
        schoolId,
        query: '',
        filters: defaultFilters ?? studentsAddToCompanyFilters,
      },
      { refetchOnMount: 'always', enabled: !!schoolId && defaultFiltersInitialized },
    );

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: AddStudentsFilters) => {
      setParams((params) => ({ ...params, filters: { ...params.filters, ...filters } }));
    },
    [setParams],
  );

  const handleResetFilters = useCallback(() => {
    setParams((params) => ({ ...params, filters: studentsAddToCompanyFilters }));
  }, [setParams]);

  const hasFiltersApplied = Boolean(
    params.filters &&
      checkHasFiltersApplied(Object.keys(studentsAddToCompanyFilters), params.filters),
  );

  const entries = useMemo(
    () =>
      data?.pages.reduce<StudentWithProducts[]>((prev, curr) => [...prev, ...curr.results], []) ??
      [],
    [data?.pages],
  );
  const total = data?.pages[0].count;

  const handleStudentSelect = useCallback(
    async ({
      studentId,
      studentName,
      companyPayerId,
      companyPayerName,
    }: HandleStudentSelectProps) => {
      if (companyPayerId && companyPayerId !== company.id) {
        const isConfirmed = await getConfirmation({
          message: $t(
            { id: 'companies-ReplaceCompanyConfirmation' },
            {
              currentCompanyName: companyPayerName,
              newCompanyName: company.name,
              studentName: studentName,
            },
          ),
        });
        if (!isConfirmed) return;
      }

      navigate(
        `/students/${studentId}/products?${STUDENT_PRODUCTS_COMPANY_ID_PARAM}=${company.id}`,
        { state: { replace: true } },
      );
    },
    [$t, company.id, company.name, getConfirmation, navigate],
  );

  useEffect(() => {
    if (!defaultFilters || !!defaultFiltersInitialized) return;

    setParams((p) => ({ ...p, filters: defaultFilters }));
    setDefaultFiltersInitialized(true);
  }, [defaultFilters, defaultFiltersInitialized, setParams]);

  return (
    <ModalSmall open onClose={onClose}>
      <ModalHeader
        active
        title={$t(
          { id: 'companies-AddStudentTo' },
          {
            companyName: company.name,
          },
        )}
      >
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalMain>
        <ModalContent active>
          <Stack gap={2.5} height="100%" sx={{ maxWidth: '100%' }}>
            <Stack gap={1}>
              <Stack
                justifyContent="center"
                position="relative"
                sx={{ '&>.MuiBox-root': { maxWidth: 'unset' } }}
              >
                <PageHeaderSearchInput
                  value={params.query || ''}
                  onChangeText={handleSetFiltersQuery}
                  placeholder={$t(
                    { id: 'people-SearchAmongType' },
                    {
                      userTypePlural: $t({
                        id: 'userType-student-plural',
                      }).toLowerCase(),
                    },
                  )}
                  sx={(theme) => ({
                    '& .FormTextField-clear': {
                      right: theme.spacing(3),
                    },
                  })}
                />
                <SearchInputFilterIconButton
                  hasAppliedIndicator={hasFiltersApplied}
                  onClick={openFilterModal}
                />
              </Stack>
              <InlineFiltersTags
                onResetFilters={handleResetFilters}
                onOpenFilters={openFilterModal}
                filters={params.filters ?? {}}
              />
            </Stack>
            {filtersModalVisible && (
              <AddStudentsToCompanyFiltersPopup
                onClose={closeFiltersModal}
                defaultFilters={studentsAddToCompanyFilters}
                onSetFilters={handleSetFilters}
                filters={params.filters ?? {}}
              />
            )}
            <MainPageGrid>
              {Boolean(!isLoading && entries.length) && <StudentsAddToCompanyHeader />}
              <GridBody>
                {entries?.map((entry) => (
                  <StudentAddToCompanyRow
                    student={entry}
                    key={entry.id}
                    handleStudentSelect={handleStudentSelect}
                  />
                ))}
                {isLoading && <SkeletonRows columnsCount={SKELETON_COLUMNS} amount={PAGE_SIZE} />}
                <SkeletonGridLoader
                  isFetching={isLoading || isFetchingNextPage}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  columnsCount={SKELETON_COLUMNS}
                  amount={Math.min(
                    PAGE_SIZE,
                    total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
                  )}
                />
              </GridBody>
            </MainPageGrid>
            {!isLoading && !entries.length && <NoSearchResultsFound />}
          </Stack>
        </ModalContent>
      </ModalMain>
    </ModalSmall>
  );
};
