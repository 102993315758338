import {
  Avatar,
  Box,
  Card,
  Icon,
  IconButton,
  Link as MuiLink,
  Skeleton,
  Stack,
  styled,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AssignedProduct,
  Company,
  DefaultPayer,
  ProductBillingType,
  useGetSchoolPaymentFrequencies,
  useGetStudentProductsQuery,
  useSendInviteMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { useNotifications } from '@schooly/components/notifications';
import { DELAY_BEFORE_HIDE_ICON_DONE, SchoolInviteStatus } from '@schooly/constants';
import {
  Attention2Icon,
  CompanyIcon,
  CopyIcon,
  Counter,
  DoneIcon,
  EditIcon,
  EmailIcon,
  MailIcon,
  PhoneIcon,
  ProfileIcon,
  SimpleButton,
  Spin,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import AccessDenied from '../../../../components/common/AccessDenied';
import { useProfile } from '../../../../context/profile/useProfile';
import { getRouteModalPathname } from '../../../../helpers/misc';
import useSchoolYears from '../../../../hooks/useSchoolYears';
import getIsAccessDeniedError from '../../../../utils/getIsAccessDeniedError';
import {
  AssignedProductsList,
  AssignedProductsListEmpty,
  AssignedProductsListSkeleton,
} from '../ProfileModalAssignedProductsList/AssignedProductsList';

export const ProfileModalPayers: FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<ProductBillingType>(ProductBillingType.Recurring);
  const isRecurringTabActive = activeTab === ProductBillingType.Recurring;

  const { permissions, schoolId = '' } = useAuth();
  const { schoolMembership, user } = useProfile();
  const { defaultValidity, isLoading: isYearsFetching } = useSchoolYears();

  const canView = permissions.includes('payer_and_product_assignment_viewer');
  const canEdit = permissions.includes('payer_and_product_assignment_manager');

  const { data, error } = useGetStudentProductsQuery(
    {
      relationId: schoolMembership?.relation_id || '',
    },
    { enabled: !!schoolMembership, refetchOnMount: 'always' },
  );

  const { data: frequenciesData } = useGetSchoolPaymentFrequencies(
    {
      school_id: schoolId,
      year_id: defaultValidity?.id ?? '',
    },
    { enabled: Boolean(schoolId && defaultValidity?.id) },
  );

  const handleEditAssignedProducts = useCallback(() => {
    navigate('products');
  }, [navigate]);

  const { recurringProducts, oneOffProducts } = useMemo(() => {
    const recurringProducts: AssignedProduct[] = [];
    const oneOffProducts: AssignedProduct[] = [];

    for (const p of data?.products ?? []) {
      if (p.product_type === ProductBillingType.Recurring) {
        recurringProducts.push(p);
        continue;
      }

      oneOffProducts.push(p);
    }

    return {
      recurringProducts,
      oneOffProducts,
    };
  }, [data?.products]);

  if (getIsAccessDeniedError(error) || !canView || !user) {
    return <AccessDenied />;
  }

  const renderProductsList = () => {
    if (!data || isYearsFetching) return <AssignedProductsSkeleton />;
    const products = isRecurringTabActive ? recurringProducts : oneOffProducts;

    const headerTitles: [string, string] = isRecurringTabActive
      ? [formatMessage({ id: 'profile-Active' }), formatMessage({ id: 'profile-NotActive' })]
      : [formatMessage({ id: 'profile-Upcoming' }), formatMessage({ id: 'profile-Issued' })];

    return (
      <Stack pb={3} gap={1.25}>
        <AssignedProductsTabs
          tabItems={tabItems}
          onChange={(_, v) => setActiveTab(v)}
          activeTab={activeTab}
        />
        {products.length ? (
          <AssignedProductsList
            products={products}
            payer={data.default_payer}
            company={data.company_payer}
            frequencies={frequenciesData?.frequencies}
            headerTitles={headerTitles}
          />
        ) : (
          <AssignedProductsListEmpty
            onAdd={canEdit && isRecurringTabActive ? handleEditAssignedProducts : undefined}
            messageId={
              isRecurringTabActive
                ? 'profile-ThereAreNoProductAssignmentsForStudent'
                : 'profile-ThereAreNoOneOffProductsForStudent'
            }
          />
        )}
      </Stack>
    );
  };

  const renderPayers = () => {
    if (!data)
      return (
        <Stack direction="row">
          <AdultSkeleton />
        </Stack>
      );
    return (
      <Stack direction="row" gap={2}>
        {data.default_payer && <DefaultPayerCard payer={data.default_payer} schoolId={schoolId} />}
        {data.company_payer && <CompanyPayerCard company={data.company_payer} />}
      </Stack>
    );
  };

  const tabItems = [
    {
      labelId: 'products-ProductType-Recurring',
      count: recurringProducts.length,
      value: ProductBillingType.Recurring,
    },
    {
      labelId: 'products-ProductType-OneOff',
      count: oneOffProducts.length,
      value: ProductBillingType.OneOff,
    },
  ];

  return (
    <Stack className="section section-wrapper" gap={3} pb={10}>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography variant="h2">{formatMessage({ id: 'profile-ProductsAndPayers' })}</Typography>
        {canEdit && (
          <IconButton onClick={handleEditAssignedProducts} inverse>
            <EditIcon />
          </IconButton>
        )}
      </Stack>
      {renderPayers()}
      {renderProductsList()}
    </Stack>
  );
};

const AdultSkeleton: FC = () => (
  <Card sx={(theme) => ({ padding: theme.spacing(1), border: 'none', flex: '0 1 50%' })}>
    <Stack direction="row" gap={1} mb={2}>
      <Skeleton variant="circular" width={30} height={30} />
      <Typography variant="h3">
        <Skeleton width={120} />
      </Typography>
    </Stack>
    <Typography py={0.75}>
      <Skeleton width={150} />
    </Typography>
    <Typography py={0.75}>
      <Skeleton width={150} />
    </Typography>
  </Card>
);

const DefaultPayerCard: FC<{ payer: DefaultPayer; schoolId: string }> = ({ payer, schoolId }) => {
  const { formatMessage } = useIntl();
  const sendInvite = useSendInviteMutation();
  const { showError } = useNotifications();
  const { isLoading } = sendInvite;

  const userInvited = payer?.invite_status === SchoolInviteStatus.Invited;
  const inviteSent = sendInvite.isSuccess;

  const sendInviteHandler = useCallback(() => {
    if (!payer) return;

    sendInvite.mutate(
      { schoolId, relationId: payer.id },
      {
        onError: showError,
      },
    );
  }, [payer, schoolId, sendInvite, showError]);

  const buttonTextId = () => {
    if (isLoading) return 'sending-invite';
    if (inviteSent) return 'inviteStatus-Invited';
    if (userInvited) return 'resend-invite';
    return 'send-invite';
  };

  const buttonIcon = () => {
    if (isLoading) return <Spin />;
    if (inviteSent) return <DoneIcon />;
    return <MailIcon />;
  };

  const name = getUserFullName(payer);

  return (
    <PayerCard>
      <Stack direction="row" alignItems="center" gap={1} mb={2}>
        <AvatarAuth user={payer} />
        <TypographyWithOverflowHint
          variant="h3"
          component={Link}
          color="text.primary"
          to={getRouteModalPathname('parent', payer)}
          sx={{ '&:hover': { textDecoration: 'underline' } }}
        >
          {name}
        </TypographyWithOverflowHint>
        {payer.invite_status !== SchoolInviteStatus.Active && (
          <Tooltip
            title={
              <Stack alignItems="flex-start" gap={1}>
                <Typography>
                  {formatMessage(
                    {
                      id: userInvited
                        ? 'profile-DidNotAcceptTheInvitation'
                        : 'profile-WasNotInvitedToSchooly',
                    },
                    { name },
                  )}
                </Typography>
                <SimpleButton
                  size="small"
                  sx={{ '&.Mui-disabled': { color: 'primary.main' } }}
                  disabled={isLoading || inviteSent}
                  startIcon={buttonIcon()}
                  onClick={sendInviteHandler}
                >
                  {formatMessage({ id: buttonTextId() })}
                </SimpleButton>
              </Stack>
            }
          >
            <Icon
              sx={{
                color: 'warning.main',
                '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
              }}
            >
              <Attention2Icon />
            </Icon>
          </Tooltip>
        )}
      </Stack>

      <PayerInfoRow
        detailLabelId="peopleDetail-EmailAddress"
        payerDetailType="email"
        content={payer.email}
        icon={<EmailIcon />}
      />
      <PayerInfoRow
        detailLabelId="peopleDetail-PhoneNumber"
        payerDetailType="phoneNumber"
        content={payer.telephone}
        icon={<PhoneIcon />}
      />
    </PayerCard>
  );
};

const CompanyPayerCard: FC<{ company: Company }> = ({ company }) => (
  <PayerCard>
    <Stack direction="row" alignItems="center" gap={1} mb={2}>
      <Avatar>
        <Icon fontSize="small" sx={{ color: 'primary.main' }}>
          <CompanyIcon />
        </Icon>
      </Avatar>
      <TypographyWithOverflowHint
        variant="h3"
        color="text.primary"
        component={Link}
        to={`/companies/${company.id}`}
        sx={{ '&:hover': { textDecoration: 'underline' } }}
      >
        {company.name}
      </TypographyWithOverflowHint>
    </Stack>

    <PayerInfoRow
      detailLabelId="companies-ContactPersonName"
      payerDetailType="contactPerson"
      content={company.contact_name}
      icon={<ProfileIcon />}
    />
    <PayerInfoRow
      detailLabelId="companies-EmailAddress"
      payerDetailType="email"
      content={company.email}
      icon={<EmailIcon />}
    />
    <PayerInfoRow
      detailLabelId="companies-PhoneNumber"
      payerDetailType="phoneNumber"
      content={company.telephone}
      icon={<PhoneIcon />}
    />
  </PayerCard>
);

const PayerCard = styled(Card)(({ theme }) => ({
  flex: '0 1 50%',
  padding: theme.spacing(1),
  border: 'none',
  ' .MuiIconButton-root': {
    visibility: 'hidden',
  },
  ':hover': {
    backgroundColor: theme.palette.background.default,
    ' .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
    ' .MuiIconButton-root': {
      visibility: 'visible',
    },
    ' .detailIcon': {
      color: theme.palette.common.grey2,
    },
  },
}));

type PayerInfoRowProps = {
  content?: string;
  icon: ReactNode;
  payerDetailType: 'email' | 'phoneNumber' | 'contactPerson';
  detailLabelId: string;
};

const PayerInfoRow: FC<PayerInfoRowProps> = ({ payerDetailType, content, detailLabelId, icon }) => {
  const [showDoneIcon, setShowDoneIcon] = useState(false);
  const { showNotification } = useNotifications();
  const { formatMessage } = useIntl();

  const onCopy = useCallback(() => {
    if (!content) return;

    navigator.clipboard.writeText(content);

    setShowDoneIcon(true);

    setTimeout(() => setShowDoneIcon(false), DELAY_BEFORE_HIDE_ICON_DONE);

    showNotification({
      textId: 'companies-SuccessfullyCopied',
      type: 'success',
      values: {
        companyDetail: formatMessage({ id: detailLabelId }),
      },
    });
  }, [content, detailLabelId, formatMessage, showNotification]);

  return (
    <Stack direction="row" justifyContent="space-between" py={0.5} height={30}>
      <Stack direction="row" gap={1.75} overflow="auto" alignItems="center">
        <Icon sx={{ color: 'common.grey' }} className="detailIcon">
          {icon}
        </Icon>
        <TypographyWithOverflowHint>
          {payerDetailType === 'email' && content ? (
            <MuiLink
              color="text.primary"
              underline="hover"
              sx={{ ':hover': { color: 'primary.main' } }}
              href={`mailto:${content}`}
              target="_blank"
            >
              {content}
            </MuiLink>
          ) : (
            content || '-'
          )}
        </TypographyWithOverflowHint>
      </Stack>

      {content &&
        (showDoneIcon ? (
          <Icon sx={{ color: 'primary.main' }}>
            <DoneIcon />
          </Icon>
        ) : (
          <IconButton onClick={onCopy}>
            <CopyIcon />
          </IconButton>
        ))}
    </Stack>
  );
};

type AssignedProductTabsProps<T> = {
  tabItems: { labelId: string; value: T; count?: number }[];
  onChange: (event: React.SyntheticEvent, newValue: T) => void;
  activeTab: T;
};

const AssignedProductsTabs = <T,>({
  tabItems,
  onChange,
  activeTab,
}: AssignedProductTabsProps<T>) => {
  const activeTabRef = useRef<HTMLDivElement>(null);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const { formatMessage } = useIntl();

  useEffect(() => setIndicatorWidth(activeTabRef.current?.offsetWidth ?? 0), [activeTab]);

  return (
    <Tabs
      value={activeTab}
      onChange={onChange}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
      sx={(theme) => ({
        paddingY: theme.spacing(1),
        minHeight: 40,
        '& .MuiTab-root': {
          minHeight: 0,
          '&:not(:last-child)': { mr: 3 },
        },
        '.MuiTab-root:not(.Mui-selected)': {
          '& .counter': {
            backgroundColor: 'common.grey2',
          },
          '& .text': {
            color: 'common.grey2',
          },
        },
        '& .MuiTabs-indicator': {
          display: 'flex',
          backgroundColor: 'transparent',
        },
        '& .MuiTabs-indicatorSpan': {
          maxWidth: indicatorWidth,
          width: '100%',
          backgroundColor: 'primary.main',
          transition: 'all 0.3s',
        },
      })}
    >
      {tabItems.map(({ labelId, value, count }) => {
        return (
          <Tab
            key={labelId}
            value={value}
            label={
              <Typography
                className="text"
                variant="h3"
                ref={activeTab === value ? activeTabRef : null}
              >
                {formatMessage({ id: labelId })}
              </Typography>
            }
            icon={
              count ? (
                <Counter
                  className="counter"
                  sx={{ minWidth: 20, minHeight: 20, textAlign: 'center' }}
                >
                  {count}
                </Counter>
              ) : undefined
            }
            iconPosition="end"
          />
        );
      })}
    </Tabs>
  );
};

const AssignedProductsSkeleton = () => {
  return (
    <Box>
      <Stack direction="row" alignItems="center" gap={3} height={40} mb={1.25}>
        {[...new Array(2)].map(() => (
          <Typography variant="h3">
            <Skeleton width={90} />
          </Typography>
        ))}
      </Stack>
      <AssignedProductsListSkeleton />
    </Box>
  );
};
