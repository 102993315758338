import {
  ApiError,
  CustomField,
  CustomFieldSelectOption,
  CustomFieldValue,
  SelectOption,
  useSetCustomFieldValueForRelationMutation,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import FormSelect2 from 'apps/web/src/components/ui/Input/FormSelect2';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form-lts';

import { useProfile } from '../../../../context/profile/useProfile';
import IntlError from '../../../../utils/intlError';
import { PropertyModal2 } from '../PropertyModal2';

export interface CustomFieldSelectModalProps {
  onClose: () => void;
  isOpen: boolean;
  field: CustomField;
  value?: CustomFieldValue;
  options?: CustomFieldSelectOption[];
}

interface CustomFieldFormArrStringData {
  value?: string;
}

export const CustomFieldSelectModal: FC<CustomFieldSelectModalProps> = ({
  isOpen,
  onClose,
  field,
  value,
  options,
}) => {
  const { showError } = useNotifications();

  const { user, schoolMembership } = useProfile();
  const setCustomFieldValueForRelation = useSetCustomFieldValueForRelationMutation();
  const selectOptions = useMemo(() => {
    return (
      options?.map<SelectOption>((v) => ({
        value: v.label,
        label: v.label,
      })) || []
    );
  }, [options]);

  const form = useForm<CustomFieldFormArrStringData>({
    mode: 'onChange',
    defaultValues: {
      value: value?.value as string,
    },
  });

  const {
    reset,
    formState: { isValid },
  } = form;

  const handleSubmit: SubmitHandler<CustomFieldFormArrStringData> = useCallback(
    async ({ value: newValue }) => {
      if (!schoolMembership) {
        return;
      }

      try {
        const res = await setCustomFieldValueForRelation.mutateAsync({
          relationId: schoolMembership.relation_id,
          field,
          value: !newValue || (newValue as unknown as boolean) === true ? null : newValue,
        });

        if (!res) return;
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        onClose();
      }

      if (!schoolMembership) {
        return;
      }
    },
    [field, onClose, schoolMembership, setCustomFieldValueForRelation, showError],
  );

  useEffect(() => {
    const selectedOption = (value?.value as string[])?.[0];
    reset({ value: selectedOption });
  }, [reset, value, isOpen]);

  return (
    <PropertyModal2
      isOpen={isOpen}
      user={user}
      submitDisabled={!isValid}
      isUpdating={setCustomFieldValueForRelation.isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <FormSelect2
        name={'value'}
        options={selectOptions}
        labelText={field.label}
        required={field.required}
      />
    </PropertyModal2>
  );
};
