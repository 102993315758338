import {
  AssessmentMethodType,
  AssessmentsGrade,
  getAssessmentsGradesForSchool,
  SORT_DIRECTION,
  useGetAssessmentsForGroupEntriesQuery,
  useGetAssessmentsForGroupQuery,
  useGetGroupDetailsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useSelectIdsContext } from '@schooly/utils/bulk-actions';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useGroup } from '../context/groups/useGroup';

export const useAssessmentsMarkbook = () => {
  const { groupId = '', group: { validity } = {} } = useGroup();

  const dateFrom = validity?.date_from ?? validity?.from_school_year?.start;
  const dateTo = validity?.date_to ?? validity?.to_school_year?.end;

  const {
    data,
    isFetching: fetchingGroup,
    error: errorGroup,
  } = useGetGroupDetailsQuery(
    { id: groupId ?? '', date_from: dateFrom, date_to: dateTo },
    { enabled: Boolean(groupId && dateFrom && dateTo) },
  );

  const group = data?.group;

  const { selectedIds } = useSelectIdsContext();
  const [fetchingLists, setFetchingLists] = useState(false);
  const [lists, setLists] = useState<Record<string, AssessmentsGrade>>({});
  const { schoolId } = useAuth();

  const {
    data: assessments = [],
    isLoading: fetchingAssessments,
    error: errorAssessments,
  } = useGetAssessmentsForGroupQuery(
    {
      groupId,
      sort: [
        { columnTextId: 'assessment_date', direction: SORT_DIRECTION.ASC },
        { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
      ],
    },
    { enabled: !!groupId, refetchOnMount: 'always' },
  );

  const {
    data: entries,
    isLoading: fetchingEntries,
    error: errorEntries,
    refetch,
  } = useGetAssessmentsForGroupEntriesQuery(
    {
      assessment_ids: assessments.map((a) => a.id),
      group_id: groupId,
    },
    { enabled: !!groupId && !!assessments?.length, refetchOnMount: 'always' },
  );

  const fetching = fetchingGroup || fetchingAssessments || fetchingEntries || fetchingLists;
  const error = errorGroup || errorAssessments || errorEntries;

  const students = group?.students;

  /**
   * Requests all lists of all attended assessments
   */
  const requestSelectLists = useCallback(async () => {
    if (assessments?.length < 1) {
      return;
    }

    const listIds = Array.from(
      assessments.reduce((prev, assessment) => {
        const method = assessment.methods.find(
          (method) => method.method_type === AssessmentMethodType.Grade,
        );

        if (method?.select_list_id) {
          prev.add(method.select_list_id);
        }

        return prev;
      }, new Set<string>()),
    );

    if (listIds.length < 1) {
      return;
    }

    setFetchingLists(true);

    const results = await getAssessmentsGradesForSchool(schoolId, listIds);

    setLists(
      results.reduce((prev, list) => {
        if (list) {
          prev[list.id] = list;
        }

        return prev;
      }, {} as Record<string, AssessmentsGrade>),
    );

    setFetchingLists(false);
  }, [assessments, schoolId]);

  useEffect(() => {
    requestSelectLists();
  }, [requestSelectLists]);

  const filteredAssessments = useMemo(() => {
    if (!assessments) return assessments;

    if (selectedIds === 'all' || selectedIds.size === 0) return assessments;

    return assessments.filter((a) => selectedIds.has(a.id));
  }, [selectedIds, assessments]);

  return {
    // Group details
    group,
    fetchingGroup,
    errorGroup,
    dateFrom,
    dateTo,
    selectedIds,
    // Assessments for group
    assessments: filteredAssessments,
    fetchingAssessments,
    errorAssessments,

    // Select lists
    lists,
    fetchingLists,
    requestSelectLists,

    // Assessment entries for group
    entries,
    fetchingEntries,
    errorEntries,

    // other
    students,
    fetching,
    error,
    request: refetch,
  };
};
