import { Button, IconButton, Stack, Switch, Typography, useTheme } from '@mui/material';
import {
  Product,
  ProductBillingType,
  ProductForm,
  ProductFormType,
  ProductSaveVariant,
  ProductTriggerType,
  SchoolYear,
  useCheckProductNameUniqueMutation,
  useGetSchoolPaymentFrequencies,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  CrossIcon,
  DeleteIcon,
  Loading,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderInput,
  ModalLarge,
  ModalSmall,
  Spin,
} from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, ControllerRenderProps, FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { getSortedFrequencies, useSchoolYearsInProduct } from '../helpers';
import { SchoolProductModalHeader } from '../SchoolProductModalHeader';
import {
  ProductSubscriptionExampleContainer,
  ProductSubscriptionExampleHeader,
} from './ProductSubscriptionSection/ProductSubscriptionExample';
import { getExampleHeaderColor } from './ProductSubscriptionSection/ProductSubscriptionOption';
import { ProductSubscriptionTypeSelect } from './ProductSubscriptionSection/ProductSubscriptionTypeSelect';
import { RadioGroupCard } from './ProductSubscriptionSection/RadioGroupCard';
import { ProductTriggerSection } from './ProductTriggerSection';
import { NameInput, SchoolProductCreateModalVariants } from './SchoolProductCreateModalVariants';

type SchoolProductCreateModalContentProps = {
  initialState?: ViewState;
  product?: Product;
  schoolId: string;
  onSave: (v: ProductForm) => void;
  onDelete?: () => void;
  onClose: () => void;
  isSaving: boolean;
  isDeleting: boolean;
};

type IntersectionId =
  | {
      ageGroupId: IntersectsAll;
      subjectId: string;
    }
  | { ageGroupId: string; subjectId: IntersectsAll }
  | {
      ageGroupId: string;
      subjectId: string;
    };

export type IntersectionIds = {
  halfDayIds: IntersectionId[];
  fullDayIds: IntersectionId[];
};

export enum ViewState {
  General = 'general',
  Variants = 'variants',
}

const defaultRegistrationTrigger = {
  trigger_type: ProductTriggerType.RegistrationUpdate,
  extra_data: { status: '' },
};

const defaultSingleInvoiceTrigger = { trigger_type: ProductTriggerType.SingleInvoice };

const defaultValues: ProductForm = {
  name: '',
  type: ProductBillingType.Recurring,
  description: '',
  obligatory: true,
  single_type: true,
  unique_types: true,
  assignment: { one_type: false },
  triggers: [defaultRegistrationTrigger],
  types: [
    {
      name: '',
      billing_connection: {
        account_id: '',
        legal_entity_id: '',
      },
      variants: [
        {
          half_day: false,
          prices: [],
          subjects: [],
          age_groups: [],
        },
      ],
    },
  ],
};

export const SchoolProductCreateModalContent: FC<SchoolProductCreateModalContentProps> = ({
  initialState = ViewState.General,
  product,
  schoolId,
  onClose,
  onSave,
  onDelete,
  isDeleting,
  isSaving,
}) => {
  const { formatMessage } = useIntl();
  const [state, setState] = useState<ViewState>(initialState);
  const checkProductNameUnique = useCheckProductNameUniqueMutation();
  const { showError } = useNotifications();
  const { propertiesMap } = useSchoolProperties({ schoolId, userType: SchoolUserRole.Student });
  const { getConfirmation } = useConfirmationDialog();
  const theme = useTheme();

  const { permissions } = useAuth();

  const canShowYearInSelect = useCallback(
    (year?: SchoolYear) => Boolean(year && !isDateInPast(year.end)),
    [],
  );
  const { selectedYear, setSelectedYear, defaultYear, yearsForSelect } =
    useSchoolYearsInProduct(canShowYearInSelect);

  const { data } = useGetSchoolPaymentFrequencies(
    { school_id: schoolId, year_id: selectedYear?.id ?? '' },
    { enabled: !!selectedYear?.id },
  );

  const { sortedFrequencies, frequencies } = useMemo(() => {
    const frequencies = data?.frequencies ? data.frequencies : [];

    return {
      frequencies,
      sortedFrequencies: getSortedFrequencies(frequencies).filter((f) => !!f.in_use),
    };
  }, [data?.frequencies]);

  const defaultTypes = useMemo(() => {
    if (product?.types.length) {
      return product.types.map((t) => ({
        ...t,
        billing_connection: {
          account_id: t.billing_connection.legal_entity_account.id,
          legal_entity_id: t.billing_connection.legal_entity_id,
          legal_entity_currency: t.billing_connection.legal_entity_currency,
        },
        year_id: selectedYear?.id,
      }));
    }

    return defaultValues.types.map((t) => ({
      ...t,
      //Based on TR-6622  we send current year_id on product create/update
      year_id: selectedYear?.id,
    }));
  }, [product?.types, selectedYear?.id]);

  const form = useForm<ProductForm>({
    defaultValues: product
      ? {
          name: product.name,
          description: product.description,
          obligatory: product.obligatory,
          triggers: product.triggers,
          single_type: product.single_type,
          unique_types: product.unique_types,
          assignment: { one_type: product.assignment.one_type },
          types: defaultTypes,
          type: product.type,
        }
      : { ...defaultValues, types: defaultTypes },
  });

  const name = form.watch('name');
  const obligatory = form.watch('obligatory');
  const isUniqueTypes = form.watch('unique_types');
  const oneTypeAssignment = form.watch('assignment.one_type');
  const types = form.watch('types');
  const triggers = form.watch('triggers');
  const type = form.watch('type');

  const isOneOff = type === ProductBillingType.OneOff;
  const [uniqueTypesError, setUniqueTypesError] = useState(false);

  const registrationTrigger = triggers.find(
    (t) => t.trigger_type === ProductTriggerType.RegistrationUpdate,
  );
  const statusName = registrationTrigger?.extra_data?.status
    ? propertiesMap.status.find((s) => s.id === registrationTrigger?.extra_data?.status)?.name
    : undefined;

  const canViewFrequency = permissions.includes('product_and_invoice_viewer');
  //TR-6684 Editing of product is temporary blocked
  const canEditProduct = !product?.id;

  const [typesIntersectionIds, setTypesIntersectionIds] = useState<IntersectionIds[]>([]);

  const findTypesApplicableIntersections = useCallback(() => {
    const { types, unique_types } = form.getValues();

    setTypesIntersectionIds(
      types.map((type) =>
        unique_types ? findProductTypesDuplicates(types) : findProductTypesDuplicates([type]),
      ),
    );
  }, [form]);

  const validateIntersections = useCallback(() => {
    //TODO Based on TR-5885 user can only create types for next year
    //Once this is changed validation for types in different years should be discussed and updated
    if (
      typesIntersectionIds.some((int) => {
        const { fullDayIds, halfDayIds } = int;

        return !!fullDayIds.length || halfDayIds.length;
      })
    ) {
      if (isUniqueTypes) {
        return formatMessage({
          id: 'products-ApplicableStudentsMustBeUniqueWithinAllProductTypes',
        });
      } else if (isOneOff) {
        return formatMessage({
          id: 'products-ApplicableStudentsMustBeUniqueWithinOneProductTypeOneOff',
        });
      }

      return formatMessage({ id: 'products-ApplicableStudentsMustBeUniqueWithinOneProductType' });
    }
  }, [formatMessage, isUniqueTypes, typesIntersectionIds, isOneOff]);

  const handleClose = useCallback(async () => {
    if (
      form.formState.isDirty &&
      !(await getConfirmation({
        textId: 'school-edit-CloseUnsavedConfirmation',
      }))
    )
      return;

    onClose();
  }, [onClose, form.formState.isDirty, getConfirmation]);

  const resetProductSubscriptionFields = useCallback(() => {
    !isUniqueTypes && form.setValue('unique_types', defaultValues.unique_types);
    oneTypeAssignment && form.setValue('assignment.one_type', defaultValues.assignment.one_type);
  }, [form, isUniqueTypes, oneTypeAssignment]);

  useEffect(() => {
    if (types.length > 1) {
      form.setValue('single_type', false);
      // many types can never become single type (when created as many types)
    } else if (canEditProduct) {
      form.setValue('single_type', true);

      //resets subscription fields for single type product
      resetProductSubscriptionFields();
    }
  }, [types, form, canEditProduct, resetProductSubscriptionFields]);

  useEffect(() => {
    const errorMessage = validateIntersections();

    if (errorMessage) {
      setUniqueTypesError(true);
    } else {
      setUniqueTypesError(false);
    }
  }, [validateIntersections]);

  const handleUniqueTypeChange = useCallback(
    (value: 'unique_types' | 'many_types') => {
      if (value === 'many_types') {
        form.setValue('unique_types', false);
      } else {
        form.setValue('unique_types', true);
      }

      findTypesApplicableIntersections();
    },
    [form, findTypesApplicableIntersections],
  );

  const handleTypeChange = useCallback(
    (field: ControllerRenderProps<ProductForm, 'type'>, type: ProductBillingType) => {
      //resets prices to set correct frequency
      if (form.formState.dirtyFields.types) {
        const newTypes = types.map(({ variants, ...rest }) => ({
          ...rest,
          variants: variants.map((v) => ({ ...v, prices: [] })),
        }));
        form.setValue('types', newTypes);
      }

      if (field.value === ProductBillingType.Recurring && type !== ProductBillingType.Recurring) {
        //resets subscription fields for one-off product
        resetProductSubscriptionFields();

        //adds single invoice trigger for one-off product
        form.setValue(
          'triggers',
          obligatory
            ? [defaultRegistrationTrigger, defaultSingleInvoiceTrigger]
            : [defaultSingleInvoiceTrigger],
        );

        field.onChange(ProductBillingType.OneOff);
        return;
      }

      if (field.value === ProductBillingType.OneOff && type !== ProductBillingType.OneOff) {
        //removes single invoice trigger for recurring product
        const filteredTriggers = triggers.filter(
          (t) => t.trigger_type === ProductTriggerType.RegistrationUpdate,
        );
        form.setValue(
          'triggers',
          filteredTriggers.length ? filteredTriggers : [defaultRegistrationTrigger],
        );

        field.onChange(ProductBillingType.Recurring);
      }
    },
    [form, obligatory, resetProductSubscriptionFields, triggers, types],
  );

  const handleObligatoryChange = useCallback(
    (field: ControllerRenderProps<ProductForm, 'obligatory'>, required: boolean) => {
      if (type === ProductBillingType.OneOff) {
        //adds single invoice trigger for one-off product
        form.setValue(
          'triggers',
          required
            ? [defaultRegistrationTrigger, defaultSingleInvoiceTrigger]
            : [defaultSingleInvoiceTrigger],
        );
      }

      if (!required) {
        field.onChange(false);
        return;
      }

      field.onChange(true);
    },
    [form, type],
  );

  const handleSubmit = useCallback(
    async (v: ProductForm) => {
      switch (state) {
        case ViewState.General: {
          const nameChanged = !product?.id || product.name !== v.name;

          if (!nameChanged) {
            form.clearErrors();
            setState(ViewState.Variants);
            return;
          }

          checkProductNameUnique.mutate(
            {
              schoolId,
              name: v.name,
            },
            {
              onSuccess: ({ is_unique }) => {
                if (!is_unique) {
                  form.setError('name', {
                    type: 'validate',
                    message: formatMessage({ id: 'products-ProductNameExists' }, { name: v.name }),
                  });
                  form.setFocus('name');
                  return;
                }

                form.clearErrors();
                setState(ViewState.Variants);
              },
              onError: showError,
            },
          );

          return;
        }

        case ViewState.Variants: {
          const errorMessage = validateIntersections();
          if (errorMessage) {
            setUniqueTypesError(true);

            showError({
              message: errorMessage,
            });
            return;
          } else {
            setUniqueTypesError(false);
          }
          onSave(v);
          return;
        }

        default:
          return;
      }
    },
    [
      formatMessage,
      checkProductNameUnique,
      form,
      onSave,
      product?.id,
      product?.name,
      schoolId,
      showError,
      state,
      validateIntersections,
    ],
  );

  const handleError = useCallback(() => {
    const errorMessage = validateIntersections();

    if (errorMessage) {
      setUniqueTypesError(true);

      showError({
        message: errorMessage,
      });
    } else {
      setUniqueTypesError(false);
    }
  }, [showError, validateIntersections]);

  if (!frequencies)
    return (
      <ModalSmall open onClose={handleClose}>
        <Loading />
      </ModalSmall>
    );

  const actionsDisabled = isDeleting || checkProductNameUnique.isLoading || isSaving;

  const deleteButton = !!onDelete && (
    <Button
      variant="outlined"
      startIcon={isDeleting ? <Spin /> : <DeleteIcon />}
      disabled={actionsDisabled}
      onClick={onDelete}
    >
      {formatMessage({ id: 'action-Delete' })}
    </Button>
  );

  if (state === ViewState.General)
    return (
      <ModalSmall open onClose={handleClose}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalHeader
              active
              title={
                <Controller
                  control={form.control}
                  name="name"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => {
                    return (
                      <ModalHeaderInput
                        placeholder={formatMessage({ id: 'products-ProductName' })}
                        autoFocus={!field.value}
                        error={!!fieldState.error}
                        helperText={getControllerErrorText(
                          fieldState.error,
                          undefined,
                          formatMessage,
                        )}
                        {...field}
                      />
                    );
                  }}
                />
              }
            >
              <IconButton onClick={handleClose}>
                <CrossIcon />
              </IconButton>
            </ModalHeader>
            <ModalContent active>
              <Stack gap={4}>
                <Stack gap={1}>
                  <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="h2">
                      {formatMessage({ id: 'products-IsThisProductRecurring' })}
                    </Typography>
                  </Stack>

                  <Stack direction="row" alignItems="stretch" gap={1}>
                    <Controller
                      control={form.control}
                      name="type"
                      render={({ field }) => {
                        return (
                          <RadioGroupCard
                            checked={field.value === ProductBillingType.Recurring}
                            onChange={() => handleTypeChange(field, ProductBillingType.Recurring)}
                            labelTextId="products-ProductType-Recurring"
                            disabled={false}
                            name="type"
                          >
                            <Typography mb={1}>
                              {formatMessage({ id: 'products-RecurringProductsAreInvoiced' })}
                            </Typography>

                            <Typography mb={1}>
                              {formatMessage({ id: 'products-SubscriptionExample-ForExample' })}
                            </Typography>

                            <ProductSubscriptionExampleContainer>
                              <Stack gap={1}>
                                <ProductSubscriptionExampleHeader
                                  textId="products-SubscriptionExample-Tuition"
                                  color={getExampleHeaderColor(
                                    theme,
                                    field.value === ProductBillingType.Recurring,
                                    !canEditProduct,
                                  )}
                                />
                              </Stack>
                            </ProductSubscriptionExampleContainer>
                          </RadioGroupCard>
                        );
                      }}
                    />

                    <Controller
                      control={form.control}
                      name="type"
                      render={({ field }) => {
                        return (
                          <RadioGroupCard
                            checked={field.value === ProductBillingType.OneOff}
                            onChange={() => handleTypeChange(field, ProductBillingType.OneOff)}
                            labelTextId="products-ProductType-OneOff"
                            disabled={false}
                            name="type"
                          >
                            <Typography mb={1}>
                              {formatMessage({ id: 'products-OneOffProductsAreInvoiced' })}
                            </Typography>

                            <Typography mb={1}>
                              {formatMessage({ id: 'products-SubscriptionExample-ForExample' })}
                            </Typography>

                            <ProductSubscriptionExampleContainer>
                              <Stack gap={1}>
                                <ProductSubscriptionExampleHeader
                                  textId="products-SubscriptionExample-ApplicationFee"
                                  color={getExampleHeaderColor(
                                    theme,
                                    field.value === ProductBillingType.OneOff,
                                    !canEditProduct,
                                  )}
                                />
                              </Stack>
                            </ProductSubscriptionExampleContainer>
                          </RadioGroupCard>
                        );
                      }}
                    />
                  </Stack>
                </Stack>

                <Stack flexDirection="column" gap={1} justifyContent="space-between">
                  <Typography variant="h2">
                    {formatMessage({ id: 'products-IsThisProductRequired?' })}
                  </Typography>

                  <Stack direction="row" alignItems="stretch" gap={1}>
                    <Controller
                      control={form.control}
                      name="obligatory"
                      render={({ field }) => {
                        return (
                          <RadioGroupCard
                            checked={field.value}
                            onChange={() => handleObligatoryChange(field, true)}
                            labelTextId="products-Product-Required"
                            disabled={!canEditProduct}
                            name="type"
                          >
                            <Typography mb={1}>
                              {formatMessage({ id: 'products-RequiredProductsAreIssued' })}
                            </Typography>
                          </RadioGroupCard>
                        );
                      }}
                    />

                    <Controller
                      control={form.control}
                      name="obligatory"
                      render={({ field }) => {
                        return (
                          <RadioGroupCard
                            checked={!field.value}
                            onChange={() => handleObligatoryChange(field, false)}
                            labelTextId="products-Product-Optional"
                            disabled={!canEditProduct}
                            name="type"
                          >
                            <Typography mb={1}>
                              {formatMessage({ id: 'products-OptionalProductsAreIssued' })}
                            </Typography>
                          </RadioGroupCard>
                        );
                      }}
                    />
                  </Stack>
                </Stack>

                <ProductTriggerSection schoolId={schoolId} canEdit={canEditProduct} />
              </Stack>
            </ModalContent>
            <ModalFooter active sx={{ justifyContent: onDelete ? 'space-between' : undefined }}>
              {deleteButton}
              <Button
                endIcon={checkProductNameUnique.isLoading ? <Spin /> : <ArrowRightIcon />}
                disabled={actionsDisabled}
                type="submit"
              >
                {formatMessage({ id: 'action-Next' })}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalSmall>
    );

  return (
    <ModalLarge open onClose={handleClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit, handleError)}>
          <SchoolProductModalHeader
            name={name}
            obligatory={obligatory}
            statusName={statusName}
            onClose={handleClose}
            triggerTextId={
              registrationTrigger ? 'products-Trigger-Status' : 'products-Trigger-SingleInvoice'
            }
            type={type}
            renderProductName={() => (
              <Controller
                control={form.control}
                name="name"
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  return (
                    <NameInput
                      placeholder={formatMessage({ id: 'products-ProductName' })}
                      autoFocus={!field.value}
                      error={fieldState.error}
                      {...field}
                    />
                  );
                }}
              />
            )}
          />

          <ModalContent display="flex" flexDirection="column">
            <SchoolProductCreateModalVariants
              findIntersections={findTypesApplicableIntersections}
              typesIntersectionIds={typesIntersectionIds}
              frequencies={sortedFrequencies}
              form={form}
              schoolId={schoolId}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              yearsForSelect={yearsForSelect}
              defaultYear={defaultYear}
              canViewFrequency={canViewFrequency}
            >
              {types.length > 1 || isOneOff ? (
                <ProductSubscriptionTypeSelect
                  form={form}
                  onSelect={handleUniqueTypeChange}
                  opened={false}
                  error={uniqueTypesError}
                  canEdit={canEditProduct}
                />
              ) : (
                <div />
              )}
            </SchoolProductCreateModalVariants>
          </ModalContent>
          <ModalFooter active sx={{ justifyContent: onDelete ? 'space-between' : undefined }}>
            {product ? (
              deleteButton
            ) : (
              <Button
                variant="outlined"
                startIcon={<ArrowLeftIcon />}
                disabled={isSaving}
                onClick={() => setState(ViewState.General)}
              >
                {formatMessage({ id: 'action-Back' })}
              </Button>
            )}
            <Button
              startIcon={isSaving ? <Spin /> : <CheckIcon />}
              disabled={isSaving}
              type="submit"
            >
              {formatMessage({ id: 'action-Save' })}
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalLarge>
  );
};

export type IntersectsAll = 'All';
export const INTERSECTS_ALL: IntersectsAll = 'All';

const findProductTypesDuplicates = (v: ProductFormType[]): IntersectionIds => {
  const fullDayVariants = v.map((v) => v.variants.filter((v) => !v.half_day)).flat();
  const halfDayVariants = v.map((v) => v.variants.filter((v) => v.half_day)).flat();

  const getNonUniqueIntersections = (v: ProductSaveVariant[]) => {
    const allCombinations: IntersectionId[] = [];

    for (const variant of v) {
      if (!variant.age_groups.length && !variant.subjects.length) continue;

      const variantAgeGroups = variant.age_groups.length ? variant.age_groups : [INTERSECTS_ALL];

      for (const ageGroupId of variantAgeGroups) {
        const variantSubjects = variant.subjects.length ? variant.subjects : [INTERSECTS_ALL];

        for (const subjectId of variantSubjects) {
          allCombinations.push({
            ageGroupId,
            subjectId,
          });
        }
      }
    }

    return allCombinations.reduce<IntersectionId[]>((acc, comb) => {
      if (
        allCombinations.filter(
          (exComb) => exComb.ageGroupId === comb.ageGroupId && exComb.subjectId === comb.subjectId,
        ).length > 1 ||
        (comb.ageGroupId === INTERSECTS_ALL &&
          allCombinations.filter((exComb) => exComb.subjectId === comb.subjectId).length > 1) ||
        (comb.subjectId === INTERSECTS_ALL &&
          allCombinations.filter(
            (exComb) =>
              exComb.ageGroupId === comb.ageGroupId || exComb.ageGroupId === INTERSECTS_ALL,
          ).length > 1)
      )
        return [...acc, comb];
      return acc;
    }, []);
  };

  return {
    halfDayIds: getNonUniqueIntersections(halfDayVariants),
    fullDayIds: getNonUniqueIntersections(fullDayVariants),
  };
};

type ProductCreateToggleProps = {
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
  labelTextIds: [string, string];
};

export const ProductCreateToggle = ({
  disabled,
  onChange,
  labelTextIds,
  checked,
}: ProductCreateToggleProps) => {
  const { formatMessage } = useIntl();

  return (
    <Stack
      flexDirection="row"
      gap={1}
      sx={{
        '& .MuiTypography-root': {
          color: disabled ? 'common.light2' : 'common.grey',
          '&.selectedOption': {
            color: disabled ? 'common.light2' : 'primary.main',
          },
        },
      }}
    >
      <Typography variant="h3" className={!checked ? 'selectedOption' : undefined}>
        {formatMessage({ id: labelTextIds[0] })}
      </Typography>
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        sx={{
          '& .MuiSwitch-thumb': { bgcolor: 'primary.main' },
          '&.MuiSwitch-root .Mui-disabled': {
            '+.MuiSwitch-track': {
              opacity: 1,
              borderColor: 'common.light2',
            },
            '.MuiSwitch-thumb': {
              bgcolor: 'common.light2',
            },
          },
        }}
      />
      <Typography variant="h3" className={checked ? 'selectedOption' : undefined}>
        {formatMessage({ id: labelTextIds[1] })}
      </Typography>
    </Stack>
  );
};
