import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import {
  AssignedProduct,
  Company,
  DefaultPayer,
  PaymentFrequency,
  ProductBillingType,
} from '@schooly/api';
import {
  EmptyListSvg,
  GridRowCell,
  GridRowItem,
  GridRowName,
  GridRowStyled,
  PlusIcon,
  PRICE_SUBTEXT_CLASS_NAME,
  randomInt,
} from '@schooly/style';
import { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { AssignedProductsTable } from './AssignedProductsTable';

const NOT_ACTIVE_LIST_STYLES = {
  ' .MuiTableRow-root:not(:hover)': {
    ' .MuiTypography-root': {
      color: 'text.secondary',
    },
    ' .MuiIcon-root': {
      color: 'common.grey',
    },
    ' .MuiAvatar-root': {
      ' .MuiIcon-root': {
        color: 'common.grey2',
      },
    },
  },
  ' .MuiTableBody-root .MuiTableRow-root:hover': {
    ' .MuiTypography-root': {
      color: 'text.primary',
    },
    ' .MuiIcon-root': {
      color: 'common.grey2',
    },
    [` .${PRICE_SUBTEXT_CLASS_NAME}`]: {
      color: 'text.secondary',
    },
  },
};

export const AssignedProductsList: FC<{
  products: AssignedProduct[];
  payer?: DefaultPayer;
  company?: Company;
  frequencies?: PaymentFrequency[];
  headerTitles: [string, string];
}> = ({ products, payer, company, headerTitles, frequencies }) => {
  const [activeProducts, notActiveProducts] = useMemo(
    () =>
      products.reduce<[AssignedProduct[], AssignedProduct[]]>(
        (prev, curr) => {
          //TR-6684 Currently all recurring products should be active and all one-off products should be not active
          if (curr.product_type === ProductBillingType.Recurring) {
            prev[0].push(curr);
          } else {
            prev[1].push(curr);
          }

          return prev;
        },
        [[], []],
      ),
    [products],
  );

  return (
    <Stack gap={2}>
      {/* active products */}
      {!!activeProducts.length && (
        <AssignedProductsTable
          products={activeProducts}
          payer={payer}
          company={company}
          frequencies={frequencies}
          headerTitle={headerTitles[0]}
        />
      )}
      {/* not active products */}
      {!!notActiveProducts.length && (
        <AssignedProductsTable
          products={notActiveProducts}
          payer={payer}
          company={company}
          frequencies={frequencies}
          headerTitle={headerTitles[1]}
          sx={NOT_ACTIVE_LIST_STYLES}
        />
      )}
    </Stack>
  );
};

interface AssignedProductsListEmptyProps {
  onAdd?: () => void;
  messageId?: string;
}

export const AssignedProductsListEmpty: FC<AssignedProductsListEmptyProps> = ({
  onAdd,
  messageId = 'profile-ThereAreNoProductAssignmentsForStudent',
}) => {
  const { formatMessage } = useIntl();

  return (
    <Stack justifyContent="center" alignItems="center" gap={2} py={3}>
      <Box
        sx={{
          ' svg': {
            width: 150,
            height: 150,
          },
        }}
      >
        <EmptyListSvg />
      </Box>
      <Typography textAlign="center" variant="h3" color="text.primary" width={250}>
        {formatMessage({ id: messageId })}
      </Typography>
      {onAdd && (
        <Button startIcon={<PlusIcon />} onClick={onAdd}>
          {formatMessage({ id: 'profile-AddProducts' })}
        </Button>
      )}
    </Stack>
  );
};

export const AssignedProductsListSkeleton: FC = memo(
  () => {
    return (
      <Stack>
        {[...new Array(6)].map((_, i) => {
          const isHeader = i < 1;
          return (
            <GridRowStyled height={isHeader ? 34 : 46} sx={{ pointerEvents: 'none' }}>
              <GridRowItem gap={1} sx={{ padding: 1 }}>
                <GridRowName minWidth={0} variant={isHeader ? 'body1' : 'h3'}>
                  <Skeleton width={`${randomInt(50, 100)}%`} />
                </GridRowName>
                <GridRowCell minWidth={200} variant={isHeader ? 'body1' : 'h3'}>
                  <Skeleton width={`${randomInt(50, 100)}%`} />
                </GridRowCell>
                <GridRowCell minWidth={120} variant={isHeader ? 'body1' : 'h3'}>
                  <Skeleton width={`${randomInt(50, 100)}%`} />
                </GridRowCell>
                <GridRowCell minWidth={60} variant={isHeader ? 'body1' : 'h3'}>
                  <Skeleton />
                </GridRowCell>
                <GridRowCell minWidth={40} variant={isHeader ? 'body1' : 'h3'}>
                  <Skeleton />
                </GridRowCell>
                <GridRowCell minWidth={60} variant={isHeader ? 'body1' : 'h3'}>
                  <Skeleton />
                </GridRowCell>
              </GridRowItem>
            </GridRowStyled>
          );
        })}
      </Stack>
    );
  },
  () => true,
);
